import React from "react";
import ScriptTag from 'react-script-tag';
function Legal() {
  return (
    <div class="page-wrapper">
    <div class="header-wrapper">
        <div class="w-full intro-line">
            <img src="/assets/images/favicon.png" class="w-14"/>
            <h1>Legal</h1>
        </div>
    </div>


    <div class="mb-4 ">
        <ul class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center"  data-tabs-toggle="#MainTabs" id="mainTabsSelect" role="tablist">
            <li class="tab-element" role="presentation">
                <button class="tab-button text-xs sm:min-w-max sm:px-6" data-tabs-target="#sign-stamp"  role="tab" >Authorities for Signing & Stamping</button>
            </li>
            <li class="tab-element sm:ml-16" role="presentation">
                <button class="tab-button" data-tabs-target="#c-contracts"  role="tab" >Clients’ Contracts</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#v-contracts"  role="tab" >Vendors Contracts</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#i-contracts"  role="tab" >Individuals’ Contracts</button>
            </li>
        </ul>
    </div>


    <div class="section-wrapper">
        <div id="MainTabs">
            <div class="hidden" id="sign-stamp" role="tabpanel" >
                <div class="w-full intro-line">
                    <h2>Authorities for Signing & Stamping</h2>
                    <p class="text-gray-400 mb-10">
                        <li>CEO: Sign & stamp client’s contracts only.</li>
                        <li>COO: Sign & stamp vendors contracts above 500K.</li>
                        <li>Managing Partner: Sign & stamp vendors contracts from 100K to 500K & non-financial obligation contracts.</li>
                        <li>Partners & directors: Sign & stamp below 100K contracts & non-financial obligation contracts.</li>
                        <li>P.s. all stamps are made through the personal assistants.</li>
                    </p>
                </div> 
            </div>

            <div class="hidden" id="c-contracts" role="tabpanel" >
                <div class="w-full intro-line">
                    <h2>Authorities for Signing & Stamping</h2>
                    <p class="text-gray-400 mb-10">
                        <li>CEO: Sign & stamp client’s contracts only.</li>
                        <li>COO: Sign & stamp vendors contracts above 500K.</li>
                        <li>Managing Partner: Sign & stamp vendors contracts from 100K to 500K & non-financial obligation contracts.</li>
                        <li>Partners & directors: Sign & stamp below 100K contracts & non-financial obligation contracts.</li>
                        <li>P.s. all stamps are made through the personal assistants.</li>
                    </p>

                    <h2>Need a Contract Template?</h2>
                    <p class="text-gray-400 mb-10">
                        For a Corporation
                        <li>For business development.</li>
                        <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                            <a target="_blank" href="/assets/files/bussiness-development-agreements.zip" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">Business Development Agreement</p>
                            </a>
                            <a target="_blank" href="#" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">MoU</p>
                            </a>
                            <a target="_blank" href="https://docs.google.com/document/d/119YMJuEwhIFY5taqZ-WMn09t_EXAIuQ4/edit?rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">NDA</p>
                            </a>
                        </div>
                    </p>

                    <h2>Need to Review a Contract?</h2>
                    <p class="text-gray-400 mb-10">
                        <li>Submit a request to COO Cc:ing all related party.</li>
                        <li>COO will respond within 24 hours to direct you based on each situation.</li>
                    </p>
                </div> 
            </div>

            <div class="hidden" id="v-contracts" role="tabpanel" >
                <div class="w-full intro-line">
                    <h2>Authorities for Signing & Stamping</h2>
                    <p class="text-gray-400 mb-10">
                        <li>CEO: Sign & stamp client’s contracts only.</li>
                        <li>COO: Sign & stamp vendors contracts above 500K.</li>
                        <li>Managing Partner: Sign & stamp vendors contracts from 100K to 500K & non-financial obligation contracts.</li>
                        <li>Partners & directors: Sign & stamp below 100K contracts & non-financial obligation contracts.</li>
                        <li>P.s. all stamps are made through the personal assistants.</li>
                    </p>

                    <h2>Need a Contract Template?</h2>
                    <p class="text-gray-400 mb-10">
                        For a Corporation
                        <li>For Services.</li>
                        <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                            <a target="_blank"  href="https://docs.google.com/document/d/1PdBAJVUgoK0Yijy62BrN_-KUdovarKzU/edit" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">Vendors Agreement</p>
                            </a>
                            <a target="_blank" href="/assets/files/training.pptx" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">Training Company Agreement</p>
                            </a>
                            <a target="_blank" href="#" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">MoU</p>
                            </a>
                            <a target="_blank" href="https://docs.google.com/document/d/119YMJuEwhIFY5taqZ-WMn09t_EXAIuQ4/edit?rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">NDA</p>
                            </a>
                        </div>
                    </p>

                    <h2>Need to Review a Contract?</h2>
                    <p class="text-gray-400 mb-10">
                        <li>Submit a request to COO Cc:ing all related party.</li>
                        <li>COO will respond within 24 hours to direct you based on each situation.</li>
                    </p>
                </div> 
            </div>

            <div class="hidden" id="i-contracts" role="tabpanel" >
                <div class="w-full intro-line">
                    <h2>Authorities for Signing & Stamping</h2>
                    <p class="text-gray-400 mb-10">
                        <li>CEO: Sign & stamp client’s contracts only.</li>
                        <li>COO: Sign & stamp vendors contracts above 500K.</li>
                        <li>Managing Partner: Sign & stamp vendors contracts from 100K to 500K & non-financial obligation contracts.</li>
                        <li>Partners & directors: Sign & stamp below 100K contracts & non-financial obligation contracts.</li>
                        <li>P.s. all stamps are made through the personal assistants.</li>
                    </p>

                    <h2>Need a Contract Template?</h2>
                    <p class="text-gray-400 mb-10">
                        For a Corporation
                        <li>For individual.</li>
                        <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                            <a target="_blank" href="https://docs.google.com/document/d/1R1eejHTDaYNcfR-cLRkPJb1XRZvJ1tuj/edit" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">A SME or a Contract-based</p>
                            </a>
                            <a target="_blank" href="#" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">MoU</p>
                            </a>
                            <a target="_blank" href="https://docs.google.com/document/d/119YMJuEwhIFY5taqZ-WMn09t_EXAIuQ4/edit?rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">NDA</p>
                            </a>
                        </div>
                    </p>

                    <h2>Need to Review a Contract ?</h2>
                    <p class="text-gray-400 mb-10">
                        <li>Submit a request to COO Cc:ing all related party.</li>
                        <li>COO will respond within 24 hours to direct you based on each situation.</li>
                    </p>
                </div> 
            </div>
            
            
            
        </div>

        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>
    </div>

    <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
</div>
  );
}

export default Legal;
