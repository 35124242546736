import React from "react";
import ScriptTag from 'react-script-tag';

function Financials() {
  return (
    <div class="page-wrapper">
      <div class="header-wrapper">
        <div class="w-full intro-line">
          <img src="/assets/images/favicon.png" class="w-14"/>
          <h1>Financials</h1>
        </div>
      </div>

      <div class="mb-4 ">
        <ul
          class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center"
          data-tabs-toggle="#MainTabs" id="mainTabsSelect"
          role="tablist">
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#authorities" role="tab">Authorities for Signing & Stamping</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#budget " role="tab">Budget
            </button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#payment" role="tab">Payment Requests</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#guarantee" role="tab">Letter of Guarantee</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#reimbursements " role="tab">Reimbursements
            </button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#invoices" role="tab">Invoices</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#visa" role="tab">Visa Card</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#taxes" role="tab">Taxes</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#banks" role="tab">TAM Banks</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#loans" role="tab">Loans</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#bank-info" role="tab">Change Bank Info</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#inquiries" role="tab">Project Inquiries</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#invoices-guidelines" role="tab">Invoices Guidelines</button>
          </li>

        </ul>
      </div>

      <div class="section-wrapper">
        <div id="MainTabs">

          <div class="hidden" id="authorities" role="tabpanel">
            <div class="w-full intro-line">
              <h2>Authorities for Signing & Stamping</h2>
              <p class="text-gray-400 mb-10">
                <li>CEO: Sign & stamp client’s contracts only.
                </li>
                <li>COO: Sign & stamp vendors contracts above 500K.</li>
                <li>Managing Partner: Sign & stamp vendors contracts from 100K to 500K.</li>
                <li>Partners & directors: Sign & stamp below 100K contracts.</li>
                <li>P.s. all stamps are made through the personal assistants.
                </li>
              </p>
            </div>
          </div>

          <div class="hidden" id="budget" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#apply-budget"
                    role="tab">Apply for a Budget</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#above-budget"
                    role="tab">Request a Payment above Budget</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#budget-status"
                    role="tab">Budget Status</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#budget-approval"
                    role="tab">Budget Approval</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#edit-budget"
                    role="tab">Edit a Budget</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="apply-budget" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Apply for a Budget</h2>
                  <p class="text-gray-400 mb-10">
                    <li>TAM has two types of budgets:
                      <br></br>
                      Functional budgets & project budget</li>
                    <li>Functional budgets are owned by partners heading functions. Ex: business
                      development, people management, finance department, digital solutions.</li>
                    <li>The finance team will reach out 1 month before end of every quarter to
                      review your budgets.
                    </li>
                    <li>The budget approval will be as per the following:
                      <br></br>
                      - Submit a budget by partner
                      <br></br>
                      - Defending budget with CFO
                      <br></br>
                      - Approving budget by COO

                    </li>
                  </p>
                </div>
              </div>

              <div class="hidden" id="above-budget" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>To Request a Payment above Budget (Functional or Projects)</h2>
                  <p class="text-gray-400 mb-10">
                    <li>First option: item transfer:<br></br>
                      - In alignment with CFO, you can transfer an amount from one item to another & make modifications within your total budget approved limit.
                    </li>
                    <li>Second option: increase budget:
                      <br></br>
                      - Obtain an approval from your partner
                      <br></br>
                      - Submit an email request to CFO & COO with a detailed request of rational & amount
                      <br></br>
                      - You will receive a response within 24 hours

                    </li>
                  </p>
                </div>
              </div>

              <div class="hidden" id="budget-status" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Budget Status</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Submit a request on TAM911 requesting budget status.</li>
                    <li>The response will come within 1 to 3 business days.
                    </li>
                    <li>TAM is working on developing live reports for quick access to financial
                      information needed.
                    </li>
                  </p>

                </div>
              </div>
              <div class="hidden" id="budget-approval" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Budget Approval</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Once a project is awarded, the project team is required to share an approved budget with finance team</li>
                    <li>An approved budget is a budget that has been revised and approved by the managing partner, or a partner he delegates to. </li>
                    <li>A Sr.Director or below is not authorized to approve a budget
</li>
                    <li>Finance team do not need to approve any budget.
</li>
                    <li>To approve a budget, please submit on <a target="_blank" href="https://monday.com"> Monday.com </a> under “Engagement Payments” board, go to your project name/code. 
</li>
                    <li>If your project is not available, please create a new group with your project information. Check other projects structure to understand your structure. </li>
                  </p>

                 

                </div>
              </div>
              <div class="hidden" id="edit-budget" role="tabpanel">

                <div class="w-full intro-line mt-10">
                <h2>Edit a Budget</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Once a project is awarded, the project team is required to share an approved budget with finance team.
</li>
                    <li>An approved budget is a budget that has been revised and approved by the managing partner, or a partner he delegates to. 
</li>
                    <li>A Sr.Director or below is not authorized to approve a budget.
</li>
                    <li>Upload budget on “Engagement Payments” on <a target="_blank" href="https://monday.com">Monday.com</a>, then get an approval from your partner on the budget. 
</li>
                    <li>Finance team do not need to approve any budget.
</li>
                    <li>Share with finance to align.
</li>
                    <li>To approve a budget, please submit on Monday.com under “Engagement Payments” board, go to your project name/code.
</li>
                    <li>If your project is not available, please create a new group with your project information. Check other projects structure to understand your structure.

                    </li>
                  </p>


                </div>
              </div>
            </div>
          </div>

          <div class="hidden" id="payment" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#payment-request"
                    role="tab">Submitting a Payment Request</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#folow-up"
                    role="tab">Following Up a Request</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#emergency"
                    role="tab">Emergency Requests
                  </button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#expedite"
                    role="tab">Expedite a Payment</button>
                </li>

                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#invoices-guidelines"
                    role="tab">invoices Guidelines</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="payment-request" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">After partner’s approval, not PR submission date,
                    all payment requests should take 3 to 5 days to be transferred. In case it was
                    not, please check expedite payment tabs, or in case of emergencies, please check
                    emergency requests tab.</p>
                  <h2>Process of Submitting a Payment Request</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Submit a request on PR attaching the invoice
                    </li>
                    <li>Make sure you follow the <a href="/assets/files/invoice-guidelines.docx">Invoice Guidelines</a></li>
                    <li>Each payment takes the following steps:
                      <br></br>
                      - Submit by employee

                      <br></br>
                      - Technical review by director or partner: To make sure that the payment is due
                      and vendor completed the work correctly.

                      <br></br>
                      - Financial review by finance team: To make sure there is a budget and allocate
                      expense to the right budget

                      <br></br>
                      - CFO: Final audit review on all payments

                      <br></br>
                      - Bank transfer

                    </li>
                    <li>Upon approving director or partner, the payment will take 3 to 5 days to
                      transfer.
                    </li>
                  </p>
                </div>
              </div>

              <div class="hidden" id="folow-up" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">After partner’s approval, not PR submission date,
                    all payment requests should take 3 to 5 days to be transferred. In case it was
                    not, please check expedite payment tabs, or in case of emergencies, please check
                    emergency requests tab.</p>
                  <h2>Following Up a Request</h2>
                  <p class="text-gray-400 mb-10">Submit a request on TAM911 requesting PR status. Include PR information</p>
                </div>
              </div>

              <div class="hidden" id="emergency" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">After partner’s approval, not PR submission date,
                    all payment requests should take 3 to 5 days to be transferred. In case it was
                    not, please check expedite payment tabs, or in case of emergencies, please check
                    emergency requests tab.</p>
                  <h2>Emergency Requests</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Directors, partners, and above are eligible to request an emergency payment.</li>
                    <li>Requests received by other grades will not be rejected.
                    </li>
                    <li>Emergency payments will take less than 24 hours to transfer payment.
                    </li>
                    <li>Transfer is subject to completing all financial documentations.
                    </li>
                  </p>
                </div>
              </div>
              <div class="hidden" id="expedite" role="tabpanel">

                <div class="w-full intro-line mt-10">
                <p class="text-gray-400 mb-10">After partner’s approval, not PR submission date, all payment requests should take 3 to 5 days to be transferred. 
In case it was not, please check expedite payment tabs, or in case of emergencies, please check emergency requests tab.</p>
                  <h2>Expedite a Payment</h2>
                  <p class="text-gray-400 mb-10">
                  If a payment was approved from your manager/partner and more than 5 days passed and still not transferred, please expedite as follows: 
                    <li>Please send an email request to your partner, CFO, accounting team, and COO. Attach: PR copy, invoice copy, and write your request to expedite.</li><br></br>
                    We will prioritize this payment.
                  </p>
                </div>
              </div>


              <div class="hidden" id="invoices-guidelines" role="tabpanel">
            <div class="w-full intro-line mt-10">
              <h2>Invoices Guidelines</h2>
              <p class="text-gray-400 mb-10">For invoices faster approval, please make sure of all the followings:
                <ol>
                  <li>You have an approved budget & a positive cashflow in your project. Visit
                    budget link for more details.
                  </li>
                  <li>
                    Invoice should be addressed to TAM Development ( شركة تام التنموية المحدودة)
                    with reference to contract/PO/Project name and Project Manager in Tam.</li>
                  <li>All invoices should have a QR code.
                  </li>
                  <li>Invoices MUST include the following information as per Saudi regulations:
                    <li>
                      Invoice date</li>
                    <li>Invoice number
                    </li>
                    <li>Supplier’s VAT identification number</li>
                    <li>TAM’s VAT number: 300789073100003</li>
                    <li>Name and address of the supplier
                    </li>
                    <li>The amount and description of deliverables.
                    </li>
                  </li>
                  <li>For TAX info, the total without TAX must be clear, then the TAX %, and then
                    the total with TAX
                  </li>
                  <li>Provide supplier’s bank information, either in the invoice or in a letter on
                    the bank letterhead.
                  </li>
                </ol>
                <br></br>
                Please download the 
                <a href="assets\files\invoice-guidelines.pdf" target="_blank">PDF </a>
                if you want to share it directly with the supplier.
              </p>
            </div>

          </div>

            </div>
          </div>

          <div class="hidden" id="guarantee" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#guarantee-r"
                    role="tab">Request a New Letter</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#guarantee-m"
                    role="tab">Modification on a Letter</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#guarantee-e"
                    role="tab">Extending a Letter of Guarantee</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="guarantee-r" role="tabpanel">

                <div class="w-full intro-line mt-10">

                  <h2>New Letter Request</h2>
                  <p class="text-gray-400 mb-10">
                    Submit a request through TAM911 with the following documents:
                    <li>Submit the Letter of Award in an email to finance.
                    </li>
                    <li>Letter of Guarantee will be issued within 3 business days.
                    </li>

                  </p>
                </div>

              </div>

              <div class="hidden" id="guarantee-m" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Modification on a Letter</h2>
                  <p class="text-gray-400 mb-10">
                    Submit a request through TAM911 with the following documents:
                    <li>Submit the modification request in the same email thread of the request.
                    </li>
                    <li>Updated letter will be issued within 3 business days.
                    </li>

                  </p>
                </div>

              </div>

              <div class="hidden" id="guarantee-e" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Extending a Letter of Guarantee</h2>
                  <p class="text-gray-400 mb-10">
                    In case project got extended, both the client or TAM can extend letter of
                    guarantee end date<br></br>
                    <br></br>

                    To extend, please get an approval from partner, then submit a request from below
                    as follow:
                    <br></br>
                    <br></br>

                    Dear Team,
                    <br></br>
                    As approved by (name of partner), please go ahead and extend LoG end date to
                    dd.mm.yy for the project (project name) (project code)<br></br>
                    <br></br>

                    Thank you
                    <br></br>
                    Cc: partner<br></br>
                    Cc: CFO
                  </p>

                </div>
              </div>

            </div>
          </div>

          <div class="hidden" id="reimbursements" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#reimbursement-r"
                    role="tab">Request a New Reimbursement</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#reimbursement-c"
                    role="tab">Corporate Agreements</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="reimbursement-r" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">TAM aims to sign corporate agreements with as
                    many third parties as possible so that employees do not pay cash from their end.
                  </p>
                  <h2>Request a New Reimbursement</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Follow payment request method</li>
                    <li>Please note that reimbursing is subject for approval. Thus, make sure your
                      payment is approved prior making the payment.</li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="reimbursement-c" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">TAM aims to sign corporate agreements with as
                    many third parties as possible so that employees do not pay cash from their end.
                  </p>
                  <h2>Corporate Agreements</h2>
                  <p class="text-gray-400 mb-10">
                    TAM signed with multiple vendors. Please review <a href="/Agreemnts">Corporate Agreements</a> section for
                    details.
                  </p>
                </div>

              </div>

            </div>
          </div>

          <div class="hidden" id="invoices" role="tabpanel">

            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#rules"
                    role="tab">Rules</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#invoice-r"
                    role="tab">Request an Invoice</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#invoice-m"
                    role="tab">Modifications on Invoice</button>
                </li>

                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#invoices-guidelines"
                    role="tab">invoices Guidelines</button>
                </li>

              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="rules" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Rules</h2>
                  <p class="text-gray-400 mb-10">All project managers or directors of project must
                    upload their projects on “Engagement Payment” board on Monday. The structure:

                    <li>Create a new group for your project code. List of project codes here.
                    </li>
                    <li>Add a row for each payment
                    </li>
                    <li>Upload the approved budget</li>
                  </p>
                </div>

              </div>
              <div class="hidden" id="invoice-r" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Request an Invoice</h2>
                  <p class="text-gray-400 mb-10">
                    <li>All budgets should be uploaded on “Engagement Payments” board on
                      <a href="https://Monday.com" target="_blank"> Monday.com</a>
                    </li>
                    <li>The group is named with the project name and code
                    </li>
                    <li>The rows under the group is the payment schedule
                    </li>
                    <li>Go to the row of the needed payment, and change invoice status to “request
                      invoice”.
                    </li>
                    <li>Finance team will email you the invoice within 24 hours.
                    </li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="invoice-m" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Modifications on Invoice</h2>
                  <p class="text-gray-400 mb-10">
                    Each modification is a special case. Please submit a request with details on TAM
                    911.</p>
                </div>

              </div>

              <div class="hidden" id="invoices-guidelines" role="tabpanel">
            <div class="w-full intro-line mt-10">
              <h2>Invoices Guidelines</h2>
              <p class="text-gray-400 mb-10">For invoices faster approval, please make sure of all the followings:
                <ol>
                  <li>You have an approved budget & a positive cashflow in your project. Visit
                    budget link for more details.
                  </li>
                  <li>
                    Invoice should be addressed to TAM Development ( شركة تام التنموية المحدودة)
                    with reference to contract/PO/Project name and Project Manager in Tam.</li>
                  <li>All invoices should have a QR code.
                  </li>
                  <li>Invoices MUST include the following information as per Saudi regulations:
                    <li>
                      Invoice date</li>
                    <li>Invoice number
                    </li>
                    <li>Supplier’s VAT identification number</li>
                    <li>TAM’s VAT number: 300789073100003</li>
                    <li>Name and address of the supplier
                    </li>
                    <li>The amount and description of deliverables.
                    </li>
                  </li>
                  <li>For TAX info, the total without TAX must be clear, then the TAX %, and then
                    the total with TAX
                  </li>
                  <li>Provide supplier’s bank information, either in the invoice or in a letter on
                    the bank letterhead.
                  </li>
                </ol>
                <br></br>
                Please download the
                <a href="assets\files\invoice-guidelines.pdf" target="_blank"> PDF </a>
                if you want to share it directly with the supplier.
              </p>
            </div>

          </div>


            </div>
          </div>

          <div class="hidden" id="visa" role="tabpanel">
            <div class="w-full intro-line mt-10">
              <h2>Virtual Visa Card</h2>
              <p class="text-gray-400 mb-10">
          TAM started a process to enable teams for logistical costs instantly. The process is as follows: 
          <br></br>
          <li> 1.	Download the document attached below, fill it, and then sign it. </li>
          <li> 2.	Go to PalmHR → HR Requests → General Requests → Visa. Submit a request for finance department approval. </li>
          <li> 3.	You will receive your virtual visa card by the accounting team </li>
          <li> 4.	The video attached will explain how to use the visa card </li>
          <li> 5.	Please make sure you follow the details on the contract </li>
          <li> 6.	Submit reimbursement through PR system  </li>

              </p>
            </div>
          </div>

          <div class="hidden" id="taxes" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#w-tax"
                    role="tab">Withholding Tax</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#vat"
                    role="tab">VAT</button>
                </li>

              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="w-tax" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Rules</h2>
                  <p class="text-gray-400 mb-10">Withholding tax are for all payments made outside Saudi Arabia.

                    <li>Withholding tax will be paid by TAM, but TAM has the right to negotiate to
                      deduct that amount from vendor.

                    </li>
                    <li>Withholding tax amount is:
                      <li>Nature of payment
                      </li>
                      <li>WHT rate (%)
                      </li>
                      <li>Management fee. 20%</li>
                      <li>Royalties, payments against technical or consultancy services, or services
                        for international telephone calls paid to the head office or any other related
                        entities. 15%
                      </li>
                      <li>Dividends distributed. 5%
                      </li>
                      <li>Rent, return on loans (interest) & insurance. 5%
                      </li>
                      <li>Technical & consulting services. 5%
                      </li>
                      <li>Airline tickets/air or sea freight. 5%
                      </li>
                      <li>Insurance & reinsurance premiums. 5%
                      </li>
                      <li>International telecommunication services. 5%
                      </li>
                      <li>Any other payments not covered above. 15%</li>
                    </li>

                  </p>
                </div>

              </div>
              <div class="hidden" id="vat" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>VAT</h2>
                  <p class="text-gray-400 mb-10">
                    <li>TAM always has to pay VAT, and if we are not sure about VAT payment, we
                      still need to pay.
                    </li>
                    <li>We exclude VAT in payments that are grants and prizes only. Generally,
                      payments where beneficiaries did not do a service for TAM is non-VAT.
                    </li>
                    <li>VAT details must be included in proposals and contracts. If proposal or
                      contracts stating that TAM will pay VAT, then we’ll have to pay it regardless of
                      the situation. Even if it is a prize or grant.
                    </li>
                    <br></br>
                    <br></br>
                    The invoices should match the E-invoice requirements as ZATCA instructions to be
                    considered as a VAT invoice.

                  </p>
                </div>

              </div>

            </div>
          </div>

          <div class="hidden" id="banks" role="tabpanel">
            <div class="w-full intro-line">
              <h2>Banks Information</h2>
              <p class="text-gray-400 mb-10">These are the list of banks TAM has dealing with:

                <li>Saudi National Bank (SNB)
                  <br></br>
                  TAM main account<br></br>
                  IBAN: SA27 1000 0010 4707 7500 0102
                </li>
                <li>AlJazira Bank Account<br></br>
                  TAM Operational account<br></br>
                  IBAN: SA67 6010 0016 0950 2699 0001
                </li>
                <li>TAM Society Expert Account
                  <br></br>
                  IBAN: SA94 1000 0010 4000 0013 1908</li>

                  <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                            <a target="_blank" href="/assets/files/tam-bank-details-aljazira.pdf" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">TAM Bank Details - AlJazira</p>
                            </a>
                            <a target="_blank" href="/assets/files/tam-bank-details-snb.pdf" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">TAM Bank Details - SNB</p>
                            </a>
                            <a target="_blank" href="/assets/files/society-experts-snb-account.pdf" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">Society Experts Details - SNB</p>
                            </a>
                        </div>
              </p>
            </div>
          </div>

          <div class="hidden" id="loans" role="tabpanel">
            <div class="w-full intro-line">
              <h2>Loans</h2>
              <p class="text-gray-400 mb-10">
                TAM signed with multiple banks to facilitate loans for all employees. To take a
                loan, please connect directly with one of the following banks:
                <li>AlRajhi bank</li>
                <li>AlJAzira bank</li>
                <li>Emirates NBD bank</li>
                <br></br>

                For all loan requests, banks might request one or more of the following:
                <li>Employee letter: You can request it from PalmHR</li>
                <li>Transfer salary to the bank: You can request it from PalmHR</li>
                <br></br>
                For non-saudis, banks have different regulations, please connect with the bank
                for further details.
              </p>
            </div>
          </div>

          <div class="hidden" id="bank-info" role="tabpanel">
            <div class="w-full intro-line">
              <h2>Change my Bank Info</h2>
              <p class="text-gray-400 mb-10">
                <li>Go to PalmHR, then go to your financial records</li>
                <li>Edit your bank information from there</li>

                <br></br>

                If you still have a problem, please send us a message from below.
              </p>
            </div>
          </div>

          <div class="hidden" id="inquiries" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#inquiries-b"
                    role="tab">Project Budget</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#code"
                    role="tab">Project Code</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#reports"
                    role="tab">Project Performance Reports</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#inquiries-g"
                    role="tab">Letter of Guarantee</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#expense"
                    role="tab">Expenses up to Date</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#inquiries-p"
                    role="tab">Payment above Budget</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#inquiries-e"
                    role="tab">Expedite a Payment</button>
                </li>

              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="inquiries-b" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Project Budget</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Your project budget is the budget approved by your partner.
                    </li>
                    <li>To approve a budget, please submit on Monday board named “Engagement
                      Payments”, and continue communications from there. Only Managers and above has
                      access to this board.
                    </li>
                  </p>
                </div>

              </div>
              <div class="hidden" id="code" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Project Code</h2>
                  <p class="text-gray-400 mb-10">
                    <li>A project code is a project identifier number, TAM will use it to allocate
                      all expenses on each project. These expenses include: resources allocation, 3rd
                      party, logistical costs, and all other costs allocated on that project.
                    </li>
                    <li>Once a letter of award or PO received, finance team will issue a project
                      code that you can use.
                    </li>
                    <li>In case you do not know your project code, please talk to your partner
                      first. If it did not help, please submit an inquiry informing us which project
                      you are working on.
                    </li>
                  </p>

                  <h2>How to Request for a Code</h2>
                  <p class="text-gray-400 mb-10">
                    <li>In proposal phase, once an item is created on “Proposal Tracking” board, the
                      finance team will add the code for that proposal in the “Code” column.
                    </li>
                    <li>Upon awarding, the information will go from proposal tracking to “Awarded
                      projects” board on<a href="https://Monday.com" target="_blank"> Monday.com</a>. Once an item is added, the finance team will add the project code.
                    </li>
                    <li>Also, you can find the project code in the “Engagement Payment” board on
                      <a href="https://Monday.com" target="_blank"> Monday.com</a>.
                    </li>
                    <li>Finally, in case you still could not find a code, and your manager did not
                      know the code, please submit a request on TAM911 and we will share the code.
                    </li>
                  </p>

                  <h2>Where to Use the Code</h2>
                  <p class="text-gray-400 mb-10">
                    The code is the main identifier for all your expenses; including time-sheet,
                    invoices, reimbursements, logistics .. etc. In your time allocation, you will
                    use the code to allocate your time. In your expenses and Payment Requests, you
                    will use the code to allocate the cost.
                    <br></br><br></br>
                    What about non-project (internal) costs? All expenses in TAM has codes, even
                    internal. You may refer to your approved budget sheet, and you will find a
                    column for all codes there.
                    <br></br><br></br>
                    In case you did not know the code, or your manager did not know the code too,
                    please submit a request on TAM911 with all expenses details, and we will share
                    the code with you.
                  </p>
                </div>

              </div>
              <div class="hidden" id="reports" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Project Performance Reports</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Performance report includes information about: contract details, payments
                      status, expenses, profit & loss report, resources cost, vendors cost.
                    </li>
                    <li>Performance report is published monthly by the finance team.
                    </li>
                    <li>In case you needed a report at anytime of the month, please send a request
                      on TAM911 if you’d like the “Project Performance Report”
                    </li>
                  </p>
                </div>

              </div>
              <div class="hidden" id="inquiries-g" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Letter of Guarantee</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Request a new letter
                      <li>Submit the Letter of Award in an email to finance.
                      </li>
                      <li>Letter of Guarantee will be issued within 3 business days.
                      </li>
                    </li>
                    <li>Modification on a letter
                      <li>Submit the modification request in the same email thread of the request.
                      </li>
                      <li>Updated letter will be issued within 3 business days
                      </li>
                    </li>
                    <li>Extending a letter of guarantee
                      <li>TAM has no control in extending the letter. The client has the full
                        authority to extend
                      </li>
                    </li>
                  </p>

                </div>

              </div>
              <div class="hidden" id="expense" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Expenses up to Date</h2>
                  <p class="text-gray-400 mb-10">This request is to know the Profit & Loss (P&L)
                    of a project. This includes the following information:

                    <li>Income: collected, invoiced on etimad, invoiced and not on etimad
                    </li>
                    <li>Expenses: resources - 3rd party</li>
                    <br></br>
                    To know your project P&L, please submit a request from below asking from project
                    P&L, please highlight if any information not mentioned above is needed.
                  </p>
                </div>

              </div>
              <div class="hidden" id="inquiries-p" role="tabpanel">

                <div class="w-full intro-line mt-10">

                  <div class="w-full intro-line mt-10">
                    <h2>Payment above Budget</h2>
                    <p class="text-gray-400 mb-10">To request for a payment above budget, you will need to:

                      <li>Submit an email request to your partner, Cc’ing Managing Partner, COO, and CFO.

                      </li>
                      <li>Based on views of Managing partner and CFO, the COO will make the decision.
                      </li>
                    </p>
                  </div>

                </div>

              </div>
              <div class="hidden" id="inquiries-e" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Expedite a Payment</h2>
                  <p class="text-gray-400 mb-10">If a payment was approved from your
                    manager/partner and more than 5 days passed and still not transferred, please
                    expedite as follows:

                    <li>Please send an email request to your partner, CFO, accounting team, and COO.
                      Attach: PR copy, invoice copy, and write your request to expedite.
                    </li>

                    <br></br>
                    We will prioritize this payment.
                  </p>
                </div>

              </div>

            </div>
          </div>

          <div class="hidden" id="invoices-guidelines" role="tabpanel">
            <div class="w-full intro-line mt-10">
              <h2>Invoices Guidelines</h2>
              <p class="text-gray-400 mb-10">For invoices faster approval, please make sure of all the followings:
                <ol>
                  <li>You have an approved budget & a positive cashflow in your project. Visit
                    budget link for more details.
                  </li>
                  <li>
                    Invoice should be addressed to TAM Development ( شركة تام التنموية المحدودة)
                    with reference to contract/PO/Project name and Project Manager in Tam.</li>
                  <li>All invoices should have a QR code.
                  </li>
                  <li>Invoices MUST include the following information as per Saudi regulations:
                    <li>
                      Invoice date</li>
                    <li>Invoice number
                    </li>
                    <li>Supplier’s VAT identification number</li>
                    <li>TAM’s VAT number: 300789073100003</li>
                    <li>Name and address of the supplier
                    </li>
                    <li>The amount and description of deliverables.
                    </li>
                  </li>
                  <li>For TAX info, the total without TAX must be clear, then the TAX %, and then
                    the total with TAX
                  </li>
                  <li>Provide supplier’s bank information, either in the invoice or in a letter on
                    the bank letterhead.
                  </li>
                </ol>
                <br></br>
                Please download the
                <a href="assets\files\invoice-guidelines.pdf" target="_blank"> PDF </a>
                if you want to share it directly with the supplier.
              </p>
            </div>

          </div>

        </div>

        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>

      </div>
      <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
    </div>
  );
}

export default Financials;
