import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    
    <nav className="">
            <div className="flex flex-wrap justify-between items-center mx-auto">
              <NavLink to="../" className="ml-2flex items-center">
                <img className="hidden h-6" src="/assets/images/logo.svg"/>
              </NavLink>
              <button data-collapse-toggle="mobile-menu" type="button" className="menu-hamburger inline-flex justify-center items-center ml-3 text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:text-gray-400 dark:hover:text-white dark:focus:ring-gray-500" aria-controls="mobile-menu-2" aria-expanded="false">
                
                <svg className="w-6 h-6 hamburger-bars" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
              </button>
              <div className="hidden w-full " id="mobile-menu">
                <ul className="menu-bg flex flex-col mt-4 rounded-lg text-center py-5">
                  <li>
                    <NavLink to="/" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700">Home</NavLink>
                  </li>
                  <li className="hidden">
                      <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" className="flex justify-between items-center py-2 pr-4 pl-3 w-full font-medium text-white rounded hover:bg-gray-100  dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 ">Dropdown <svg className="ml-1 w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></button>
                      
                      <div id="dropdownNavbar" className="hidden z-10 w-44 font-normal bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                          <ul className="py-1 text-sm text-white dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                            <li>
                              <NavLink to="/" className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</NavLink>
                            </li>
                            <li>
                              <NavLink to="/" className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</NavLink>
                            </li>
                            <li>
                              <NavLink to="/" className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</NavLink>
                            </li>
                          </ul>
                          <div className="py-1">
                            <NavLink to="/" className="block py-2 px-4 text-sm text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Sign out</NavLink>
                          </div>
                      </div>
                  </li>
                  <li>
                    <NavLink to="/Overall" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Tam Overall</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Human_Resources" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Human Resources</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Legal" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Legal</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Financials" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Financials</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Tech" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Technology & Subscriptions</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Agreemnts" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Corporate Agreements</NavLink>
                  </li>
                  
                  <li>
                    <NavLink to="/Operation" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Operations & delivery</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Guidelines" className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100  dark:hover:bg-gray-700 hover:text-gray-700 ">Design & Guidelines</NavLink>
                  </li>
                  
                </ul>
              </div>
            </div>
          </nav>
              
           
  );
}

export default Navigation;
