import React from "react";
import emailjs from "emailjs-com";
import { useRef } from "react";
import ScriptTag from 'react-script-tag';
function Human_Resources() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    var submitBtn = document.getElementById("complaint-submit");
    var formContent = document.getElementById("complain_form");
    
    submitBtn.value = 'sending..'
    submitBtn.disabled = true;

    emailjs
      .sendForm(
        "service_mjw89mi",
        "template_aj6ftvr",
        form.current,
        "NJaCoui6IzRWQBiOY"
      )
      .then(
        (result) => {
          console.log(result.text);
          formContent.innerHTML="<p>Thank you, you message has been recieved</p>";
        },
        (error) => {
          console.log(error.text);
          formContent.innerHTML="<p>Error - " + error.text + "</p>";
        }
      );
  };

  return (
    <div class="page-wrapper">
      <div class="header-wrapper">
        <div class="w-full intro-line">
          <img src="/assets/images/favicon.png" class="w-14"/>
          <h1>Human Resources</h1>
        </div>
      </div>

      <div class="mb-4 ">
        <ul
          class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center"
          data-tabs-toggle="#MainTabs"
          id="mainTabsSelect"
          role="tablist">
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#business-trips" role="tab">Business Trips</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#hiring" role="tab">Hiring</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#insurance" role="tab">Health Insurance
            </button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#relations" role="tab">Government Relations</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#palm" role="tab">PalmHR</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#business" role="tab">Business Cards</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#promotions" role="tab">Promotions & Evaluations</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#training" role="tab">Training & Development</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#stamps" role="tab">Chamber Stamps</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#loan" role="tab">Loan</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#complaints" role="tab">Complaint</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#office" role="tab">Office</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#staffing" role="tab">Staffing</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#leave" role="tab">Leave</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#r-letter" role="tab">Recommendation letter</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#issue-visa" role="tab">Issue Visa </button>
          </li>
        </ul>
      </div>

      <div class="section-wrapper">
        <div id="MainTabs">

          <div class="hidden" id="business-trips" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#flight"
                    role="tab">Booking a Flight</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#car"
                    role="tab">Renting a Car</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#transportation"
                    role="tab">Transportation Services</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#hotel"
                    role="tab">Hotel Booking</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#reimbursements"
                    role="tab">Reimbursements</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="flight" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Business Trips</h2>
                  <p class="text-gray-400">Please click 
                    <a href="/assets/files/business-trips.pptx"> here </a>
                    for a detailed policy on business trips.</p>
                  <p class="text-gray-400 mb-10">
                    <li>All trips expenses must be allocated to an approved budget.</li>
                    <li>Apply all expenses on PR system.</li>
                    <li>TAM signed agreements with 3rd parties for a better business trip
                      experience, please review other sections to know more about them.</li>
                  </p>
                </div>

                <div class="w-full intro-line">
                  <h2>Booking a Flight</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Directly book from a flight currier.</li>
                    <li>Keep the invoice of the exact amount paid.</li>
                    <li>Submit
                    <a href="https://reqmanage.tamhub.com/home" target="_blank"> payment request</a> , and make sure its budgeted and within your grade limit.</li>
                    <li>For partners and above, your assistance will apply the above.</li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="car" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Business Trips</h2>
                  <p class="text-gray-400">Please click 
                    <a href="/assets/files/business-trips.pptx"> here </a>
                    for a detailed policy on business trips.</p>
                  <p class="text-gray-400 mb-10">
                    <li>All trips expenses must be allocated to an approved budget.</li>
                    <li>Apply all expenses on PR system.</li>
                    <li>TAM signed agreements with 3rd parties for a better business trip
                      experience, please review other sections to know more about them.</li>
                  </p>
                </div>

                <div class="w-full intro-line">
                  <h2>Renting a Car</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Car rental for heads and above only.</li>
                    <li>TAM signed with “theeb” a
                      <a target="_blank" href="/Agreemnts"> Corporate Agreement</a>, please check details in
                      <a href=""> Logistics. </a>
                    </li>
                    <li>Visit their website and make necessary bookings.</li>
                    <li>Keep the invoice of the exact amount paid.</li>
                    <li>Submit <a href="https://reqmanage.tamhub.com/home" target="_blank">payment request</a>, and make sure its budgeted and within your grade limit.
                    </li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="transportation" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Business Trips</h2>
                  <p class="text-gray-400">Please click 
                    <a href="/assets/files/business-trips.pptx"> here </a>
                    for a detailed policy on business trips.</p>
                  <p class="text-gray-400 mb-10">
                    <li>All trips expenses must be allocated to an approved budget.</li>
                    <li>Apply all expenses on PR system.</li>
                    <li>TAM signed agreements with 3rd parties for a better business trip
                      experience, please review other sections to know more about them.</li>
                  </p>
                </div>

                <div class="w-full intro-line">
                  <h2>Transportation Services</h2>
                  <p class="text-gray-400 mb-10">
                    <li>You can use Careem, we have a signed
                      <a target="_blank" href="/Agreemnts"> Corporate Agreement</a>.</li>
                    <li>Book a trip, enter TAM, enter the project code, then enjoy the ride. For
                      more details, check the
                      <a href=""> Logistics </a>
                      in
                      <a target="_blank" href="/Agreemnts"> Corporate Agreement </a>.</li>
                    <li>You can use other transportation services and reimburse.</li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="hotel" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Business Trips</h2>
                  <p class="text-gray-400">Please click 
                    <a href="/assets/files/business-trips.pptx"> here </a>
                    for a detailed policy on business trips.</p>
                  <p class="text-gray-400 mb-10">
                    <li>All trips expenses must be allocated to an approved budget.</li>
                    <li>Apply all expenses on PR system.</li>
                    <li>TAM signed agreements with 3rd parties for a better business trip
                      experience, please review other sections to know more about them.</li>
                  </p>
                </div>

                <div class="w-full intro-line">
                  <h2>Hotel Booking</h2>
                  <p class="text-gray-400 mb-10">
                    <li>TAM signed a corporate agreement with several hotels.</li>
                    <li>Please explore the method for each from
                      <a target="_blank" href="/Agreemnts"> Corporate Agreement</a>, then go to
                      <a href=""> Logistics</a>.</li>
                    <li>If the hotel is fully booked, please pick another hotel.</li>
                    <li>If the hotel did not give the agreements rates, please send an email to
                      hotel’s manager.</li>
                    <li>Up to date, you will have to pay cash then reimburse, the accounting team is
                      working on it to improve that experience.</li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="reimbursements" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Business Trips</h2>
                  <p class="text-gray-400">Please click 
                    <a href="/assets/files/business-trips.pptx"> here </a>
                    for a detailed policy on business trips.</p>
                  <p class="text-gray-400 mb-10">
                    <li>All trips expenses must be allocated to an approved budget.</li>
                    <li>Apply all expenses on PR system.</li>
                    <li>TAM signed agreements with 3rd parties for a better business trip
                      experience, please review other sections to know more about them.</li>
                  </p>
                </div>

                <div class="w-full intro-line">
                  <h2>Reimbursements</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Please make sure you have a
                    <a href="https://reqmanage.tamhub.com/home" target="_blank"> budget</a>, and you follow the policy limits.</li>
                    <li>Submit a reimbursement on your invoices to your manager.</li>
                    <li>Submit a reimbursement through Payment Request (PR).</li>
                    <li>Payments will be made within 3 working days.</li>
                  </p>
                </div>

              </div>
            </div>
          </div>

          <div class="hidden" id="hiring" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#full-timer"
                    role="tab">Hire a Full-Timer</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#project-based"
                    role="tab">Hire a Project-Based or Part-Time</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#intl-expert"
                    role="tab">Hire an International Expert (Business Visa)</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#workable"
                    role="tab">Workable</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="full-timer" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">TAM hires based on partner approval. HR is
                    currently working on using a dedicated software to track all candidates and
                    answer all status questions. We expect to kick off the platform on October 2nd.</p>
                  <h2>Hiring a Full-Timer Process</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Manpower plans require an approval from a partner & COO. Manpower is the
                      list of vacancies requires for a specific period.</li>
                    <li>Submit a request to your partner via email.</li>
                    <li>If approved, partner will submit to COO.</li>
                    <li>If approved, talent acquisition team will be assigned to fill that vacancy
                      as per their plan.</li>
                    <li>If you need a status update on the vacancy, interviews, starting dates,
                      please submit a ticket on 911.</li>
                  </p>
                </div>
              </div>

              <div class="hidden" id="project-based" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">TAM hires based on partner approval. HR is
                    currently working on using a dedicated software to track all candidates and
                    answer all status questions. We expect to kick off the platform on October 2nd.</p>
                  <h2>Hiring a Project-Based or Part-Time Process</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Ensure you have an available budget.</li>
                    <li>Heads, directors, and above are authorized to hire a project-based team member.</li>
                    <li>Sign contract-based template agreement.</li>
                    <li>Send all highlighted documentation to HR and contract to process the payment.</li>
                    <li>The agreement will be monthly, and renewable.</li>
                  </p>
                  <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="https://docs.google.com/document/d/1R1eejHTDaYNcfR-cLRkPJb1XRZvJ1tuj/edit"
                      class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Contract-Based Template Agreement</p>
                    </a>
                    <a
                      target="_blank"
                      href="https://docs.google.com/document/d/119YMJuEwhIFY5taqZ-WMn09t_EXAIuQ4/edit?rtpof=true&sd=true"
                      class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">NDA</p>
                    </a>
                    <a
                      target="_blank"
                      href="/assets/files/temp-staff.pptx"
                      class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Temp Staff</p>
                    </a>
                  </div>
                </div>
              </div>

              <div class="hidden" id="intl-expert" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-10">TAM hires based on partner approval. HR is
                    currently working on using a dedicated software to track all candidates and
                    answer all status questions. We expect to kick off the platform on October 2nd.</p>
                  <h2>Hiring an International Expert (Business Visa) Process</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Ensure you have an available budget.</li>
                    <li>Heads, directors, and above are authorized to hire a project-based team member.</li>
                    <li>Sign contract-based template agreement.</li>
                    <li>Send all highlighted documentation to HR and contract to process the payment.</li>
                    <li>The agreement will be monthly, and renewable.</li>
                  </p>

                  <h2>To Issue a Work Visa</h2>
                  <p class="text-gray-400 mb-10">
                    <p>Collect passport copy & submit a request on 911 with following details:</p>
                    <li>Passport copy & resume.</li>
                    <li>Start date and end date of stay.</li>
                    <li>Role of the candidate and in which project.</li>
                    <li>Who is the direct manager.</li>
                  </p>

                  <h2>For all Logistics</h2>
                  <p class="text-gray-400 mb-10">
                    For hotel bookings, flights, and transportation, the international expert follow
                    the same policy as an employee. However, the executive assistant might help in
                    all logistical needs.
                  </p>

                  <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="/assets/files/invitation-letter-business-temporary-work-visa.docx"
                      class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Letter to Invite Template</p>
                    </a>

                  </div>

                </div>
              </div>

              <div class="hidden" id="workable" role="tabpanel">

                <div class="w-full intro-line mt-10">
                   <p class="text-gray-400 mb-10">
                   Workable is our recruitment platform. We manage all hiring applications from submission to offers through workable. The video below explains how to use workable. Here are the main questions you might need to know:
                    <li>What is the status of a candidate?
                      <li>You can search for a candidate at workable easily. Write his/her name in the search box. </li>
                      <li>You will see the status and all evaluation comments</li>
                      <li>If you can not see the candidate, please submit a ticket on 911.</li>
                    </li>
                    <li>I requested a job, can I see who applied? 
                      <li>This request is to view and evaluate candidates in a job</li>
                      <li>Only reviewers and hiring managers are authorized to have access to workable. </li>
                      <li>If you are not assigned a job-vacancy, please submit a request on 911. </li>
                    </li>
                    <li>Candidates starting date? 

                      <li>If you know the candidate name, you can search directly and all their information will come up. </li>
                      <li>If you want to see a candidate who accepted TAM’s offer and is scheduled to join TAM, please submit a ticket to TAM911</li>

                    </li>
                    </p>

                  

                </div>
              </div>
            </div>
          </div>

          <div class="hidden" id="insurance" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#cover"
                    role="tab">Health Insurance Cover</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#upgrade"
                    role="tab">Upgrade your Insurance</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#parents"
                    role="tab">Parents Insurance</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="cover" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>To Know your Insurance Information</h2>
                  <p class="text-gray-400 mb-10">
                    Please
                    <a href="https://member.bupa.com.sa/Login.aspx" target="_blank"> visit </a>
                    <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="/assets/files/insurance.pptx"
                      class="mb-5 mt-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Insurance Information</p>
                    </a>
                    </div>
                  </p>
                </div>
              </div>

              <div class="hidden" id="upgrade" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>To Upgrade your Insurance</h2>
                  <p class="text-gray-400 mb-10">
                    <li>TAM does not upgrade insurance for employees or their dependence.
                    </li>
                    <li>In emergency cases, TAM can offer loans to upgrade. Cases are subject to
                      approval.</li>
                    <li>To apply for your case, please submit a ticket on 911.</li>
                    <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="/assets/files/insurance.pptx"
                      class="mb-5 mt-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Insurance Information</p>
                    </a>
                    </div>
                  </p>
                </div>
              </div>

              <div class="hidden" id="parents" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Both parents insurance</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Parents insurance is a program by Bupa, and you can insure both your parents
                      through
                      <a href="https://bupa.com.sa/family/saudi-parents" target="_blank"> this link</a>.</li>
                    <li>The full process will be through Bupa and you will not have to align with HR.</li>
                   
                  </p>
                  <h2>Single parent insurance (father or mother)</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Bupa does not offer an insurance for an individual parent, it has to be both. </li>
                    <li>In case you’d like to insure on of your parents, please do the following: 
                      <li>Contact bupa for a cost estimation </li>
                      <li>Share a request on TAM911 that you’d like to insure your parent</li>
                    </li>
                    <li>Regarding the financials: 
                      <li>In case Bupa rejected to share a cost estimation with you, please submit a request on TAM911. Note: Cost estimation for an individual parent (with no disclaimers on any diseases) might be around 20,000 SAR per year. </li>
                      <li>TAM will collect a cost estimation with an invoice for your reference. </li>
                      <li>TAM will share the invoice with you for payment, once paid, you’ll receive your insurance credentials through HR. </li>
                    </li>
                    <li>I can not pay for that, can TAM support? 
                      <li>No, up to date, TAM do not have a parent insurance policy. </li>
                      <li>In specific rare cases, TAM can offer the amount as a loan deducted over three months from the employee’s salary. </li>
                    </li>
                    </p>
                    
                 
                  <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="/assets/files/insurance.pptx"
                      class="mb-5 mt-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Insurance Information</p>
                    </a>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden" id="relations" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#mofa"
                    role="tab">Ministry of Foreign Affairs</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#chamber"
                    role="tab">Chamber of Commerce</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#moi"
                    role="tab">Ministry of Interior</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="mofa" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Exit & Re-entry Visa</h2>
                  <p class="text-gray-400 mb-10">
                    Submit a request through TAM911 at least 3 days before your trip.</p>

                  <h2>Work Visa</h2>
                  <p class="text-gray-400 mb-10">
                    Submit a request through TAM911 with the following documents:
                    <li>Passport copy</li>
                    <li>Resume</li>
                    <li>Date of entry, and estimated duration of staying in Saudi</li>
                    <li>Purpose of the visit: ex: working on “xxx” project</li>
                    <li>Direct manager name</li>
                    <br></br>
                    The visa will be issued within 5 business days.
                  </p>
                </div>

              </div>

              <div class="hidden" id="chamber" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Authentication</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Send an open version copy of the letter required to authenticate over TAM911.</li>
                    <li>If the letter is not open version, we will not be able to help you.</li>
                    <li>Your document will be received within 3 business days.</li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="moi" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Iqama Transfer</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Once an employment contract is signed, TAM will start processing iqama
                      transfer process.</li>
                    <li>The process takes approximately 2 weeks.</li>
                    <li>In case you want to follow up on status, please submit a ticket on TAM911.</li>
                  </p>

                  <h2>Family Visit</h2>
                  <p class="text-gray-400 mb-10">
                    Submit a request on TAM911 to issue an invitation letter for the family, please
                    include:
                    <li>Passport copy of all family members</li>
                    <li>Estimated date of arrivals</li>
                    <li>National address</li>
                    <br></br>
                    The process will take from 5 to 10 business days. In case you want to follow up
                    on status, please submit a ticket on TAM911.
                  </p>

                  <h2>Iqama Renewal</h2>
                  <p class="text-gray-400 mb-10">
                    <li>TAM automatically renew your iqama when its due. No need to follow up or
                      request.</li>
                    <li>In case you needed a sooner renewal, please submit a ticket on TAM911 for
                      approval.</li>
                  </p>
                </div>
              </div>

            </div>
          </div>

          <div class="hidden" id="palm" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#palmhr"
                    role="tab">About PalmHR</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#palmservices"
                    role="tab">Services</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="palmhr" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>PalmHR</h2>
                  <p class="text-gray-400 mb-10">
                    <a href="" target="_blank"> palm.hr </a>
                    is a fully automated HRMS and your path to building a streamlined and consistent
                    employee experience. It has everything modern HR teams need to manage, grow and
                    engage their people without compromise.
                    <br></br>
                    <br></br>
                    In
                    <a href="" target="_blank"> palm.hr </a>
                    you will find all your personal information, contract information, financial
                    information, vacations, and you are able to submit a reimbursement request.
                  </p>
                </div>

              </div>

              <div class="hidden" id="palmservices" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Services</h2>
                  <p class="text-gray-400 mb-10">
                    <a href="" target="_blank"> palm.hr </a>
                    is the official channel to:
                    <li>Request all employment and governmental letters</li>
                    <li>Request all type of leaves, including remote work</li>
                    <li>View team directory, vacations, and special events</li>
                    <li>View TAM news</li>
                    <br></br>
                    Please submit a ticket in Portal if you have any questions about
                    <a href="" target="_blank">palm.hr</a>
                  </p>
                </div>

              </div>

            </div>
          </div>

          <div class="hidden" id="business" role="tabpanel">

            <div class="w-full intro-line">
              <h2>To Get Business Cards</h2>
              <p class="text-gray-400 mb-10">
                Please submit a request on TAM911. Please include the following info:
                <li>Quantity</li>
                <li>Budget code</li>
                <br></br>

                Please submit a request below with the following information:
                <li>Name Arabic</li>
                <li>Name English</li>
                <li>Phone number: +966</li>
                <li>Email</li>
                <li>Position Arabic</li>
                <li>Position English</li>
                <br></br>
                Your cards will be delivered to HR office in 3 days.
              </p>
            </div>
          </div>

          <div class="hidden" id="promotions" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#p-salary"
                    role="tab">Request a Promotion or Salary Increase</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#e-promotion"
                    role="tab">Expected a Promotion & Didn’t Get it</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#ev-criteria"
                    role="tab">Evaluation Criteria</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="p-salary" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Important Activities to Know</h2>
                  <p class="text-gray-400 mb-10">
                    TAM values are about talents; grow with wellbeing, own your impact, respect the
                    truth, win as a team, and adapt and innovate. The employee growth comes as a
                    priority to TAM’s leadership.
                    <br></br>
                    <br></br>
                    TAM has an approved salary scale from board of directors, as well as an approved
                    compensation structure and incentives.
                    <br></br>
                    <br></br>
                    TAM evaluate employees twice a year. Once every 6 months. Then, partners decide
                    on promotions in the last two weeks of every 6 months (last two weeks of June
                    and last two weeks of December).
                    <br></br>
                    <br></br>
                    It is the responsibility of the employee and the partner to conduct these
                    evaluation, and you have all 6 months to do so.
                    <br></br>
                    <br></br>
                    Every 6-months, based on evaluation, TAM partners meet to discuss and decide on
                    promotions list. Also, based on company performance, TAM partners discuss and
                    decide on salary increases.
                    <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="/assets/files/evaluation-promotion.pptx"
                      class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Evaluation & Promotion</p>
                    </a>
                    </div>
                  </p>

                  <h2>Request a Promotion or Salary Increase</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Two windows of promotions and salary increases: last two weeks of June, and
                      last two weeks of December.
                    </li>
                    <li>Make sure you sit with your direct manager and partner for an evaluation
                      session during the six months prior the promotion window.
                    </li>
                    <li>Your partner will submit the recommendation, and TAM partners will discuss
                      all promotions together.
                    </li>
                    <li>Promotions will be announce on July and in January.
                    </li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="e-promotion" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Important Activities to Know</h2>
                  <p class="text-gray-400 mb-10">
                    TAM values are about talents; grow with wellbeing, own your impact, respect the
                    truth, win as a team, and adapt and innovate. The employee growth comes as a
                    priority to TAM’s leadership.
                    <br></br>
                    <br></br>
                    TAM has an approved salary scale from board of directors, as well as an approved
                    compensation structure and incentives.
                    <br></br>
                    <br></br>
                    TAM evaluate employees twice a year. Once every 6 months. Then, partners decide
                    on promotions in the last two weeks of every 6 months (last two weeks of June
                    and last two weeks of December).
                    <br></br>
                    <br></br>
                    It is the responsibility of the employee and the partner to conduct these
                    evaluation, and you have all 6 months to do so.
                    <br></br>
                    <br></br>
                    Every 6-months, based on evaluation, TAM partners meet to discuss and decide on
                    promotions list. Also, based on company performance, TAM partners discuss and
                    decide on salary increases.
                    <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="/assets/files/evaluation-promotion.pptx"
                      class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Evaluation & Promotion</p>
                    </a>
                    </div>

                  </p>

                  <h2>Expected a Promotion & Didn’t Get it</h2>
                  <p class="text-gray-400 mb-10">
                    <li>TAM review the list of all employees in TAM. Most probably there was no mistake.

                    </li>
                    <li>Please connect with your direct manager and partner to discuss.
                    </li>
                    <li>If there was a mistake, TAM will fix it.
                    </li>

                  </p>
                </div>

              </div>

              <div class="hidden" id="ev-criteria" role="tabpanel">

              <div class="w-full intro-line mt-10">
                  <h2>Important Activities to Know</h2>
                  <p class="text-gray-400 mb-10">
                    TAM values are about talents; grow with wellbeing, own your impact, respect the
                    truth, win as a team, and adapt and innovate. The employee growth comes as a
                    priority to TAM’s leadership.
                    <br></br>
                    <br></br>
                    TAM has an approved salary scale from board of directors, as well as an approved
                    compensation structure and incentives.
                    <br></br>
                    <br></br>
                    TAM evaluate employees twice a year. Once every 6 months. Then, partners decide
                    on promotions in the last two weeks of every 6 months (last two weeks of June
                    and last two weeks of December).
                    <br></br>
                    <br></br>
                    It is the responsibility of the employee and the partner to conduct these
                    evaluation, and you have all 6 months to do so.
                    <br></br>
                    <br></br>
                    Every 6-months, based on evaluation, TAM partners meet to discuss and decide on
                    promotions list. Also, based on company performance, TAM partners discuss and
                    decide on salary increases.
                    <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a
                      target="_blank"
                      href="/assets/files/evaluation-promotion.pptx"
                      class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                      <img class="w-5" src="/assets/images/download-file.svg"></img>
                      <p class="mx-5">Evaluation & Promotion</p>
                    </a>
                    </div>

                  </p>

                  <h2>Evaluation Criteria</h2>
                  <p class="text-gray-400 mb-10">
                    <li>TAM is working on a unified evaluation criteria for all teams. 

                    </li>
                    <li>At the moment, please connect with your director or partner to know your evaluation criteria.
                    </li>
                    

                  </p>
                </div>

              </div>
            </div>
          </div>

          <div class="hidden" id="training" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#t-needs"
                    role="tab">Identify Training Needs </button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#t-budget"
                    role="tab">Training Budget</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#ap-training"
                    role="tab">Apply for a Training</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="t-needs" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Identify Training Needs </h2>
                  <p class="text-gray-400 mb-10">
                    <li>Individual need: Based on your ongoing discussion with your manager, and the last performance review session, you should have agreed on areas of 
       development to focus on on. Your training spendings and time is prioritized to go there.
</li>
                    <li>Group-based training: based on your job, level, business line and so on; there are group trainings that you will be enrolled into by HR.</li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="t-budget" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Training Budget</h2>
                  <p class="text-gray-400 mb-10">
                  <li>Every employee, according to his grade and as per approved offer, has a training budget. 
</li>
                  <li>Training budget is annually, and it includes all logistics cost.
</li>
                  <li>Training budget can not be replaced in cash.
</li>
                  <li>TAM reserves the right to spend the training budget where best helps the business, and reserve the right to refuse training courses that is not fit.
</li>
                  <li>In case a training is above budget, please get your manager and partner approval, if approved, please send an email to Professional Development 
       Unit and CFO for approval. 
</li>
                  <li>In case of group training, TAM deducts the budget equally from all assigned TAMers to attend.
</li>
<br></br>
To know about your training budget balance, please send a request on TAM911. 
                  </p>
                </div>

              </div>

              <div class="hidden" id="ap-training" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Apply for a Training</h2>
                  <p class="text-gray-400 mb-10">
                  <li>TAM structures its training following the 70-20-10 formula. 70% of your professional development is on the job, 20% online, and 10% external training. </li>
<li>To request for training, please collect an approval from your direct manager via email. </li>
<li>Only directors and partners are authorized to approve a training for employees</li>
<li>For financials, please submit a PR for payments. </li>
<li>For a leave for training, please go to PalmHR → Time Requests → Leave → Training</li>
<li>Your request will be sent to your direct manager → then will go to HR for processing </li>

                 </p>
                </div>

              </div>

            </div>
          </div>

          <div class="hidden" id="stamps" role="tabpanel">
            <div class="w-full intro-line">
              <h2>Chamber of Commerce Stamps</h2>
              <p class="text-gray-400 mb-10">
                <li>Please attach the form or letter require stamping on
                  <a href="" target="_blank"> TAM911</a>
                </li>
                <li>HR is not responsible about the content of the letter</li>
                <li>Chamber of commerce might take 3 to 5 days to stamp it</li>
                <li>Once signed, we will send it back to you</li>
              </p>
            </div>
          </div>

          <div class="hidden" id="loan" role="tabpanel">
            <div class="w-full intro-line">
              <h2>TAM Loan</h2>
              <p class="text-gray-400 mb-10">
                TAM signed with multiple banks to facilitate loans for all employees. To take a
                loan, please connect directly with one of the following banks:
                <li>AlRajhi bank</li>
                <li>AlJAzira bank</li>
                <li>Emirates NBD bank</li>
                <br></br>

                For all loan requests, banks might request one or more of the following:
                <li>Employee letter: You can request it from PalmHR</li>
                <li>Transfer salary to the bank: You can request it from PalmHR</li>
                <br></br>
                For non-saudis, banks have different regulations, please connect with the bank
                for further details.
              </p>
            </div>
          </div>

          <div class="hidden" id="complaints" role="tabpanel">
          <div class="w-full intro-line">
              <h2>We hear you!</h2>
              <p class="text-gray-400 mb-10">
              Speak the truth is a value we stand by in TAM, and all leaders and TAMers follow that. Your thoughts will be heard, and your complaints will be addressed. 
                        <br></br>
                Please write your complaint below, and it will be shared with COO only.
              </p>

              <form id="complain_form" ref={form} onSubmit={sendEmail}>
            <div class="flex flex-wrap w-full gap-8">
              <input class="form-field" placeholder="Name (Optional)" type="text" name="name"/>
              <input class="form-field" placeholder="Email  (Optional)" type="text" name="email"/>
              
            </div>
            
            <textarea class="form-field" placeholder="Message"  name="message" ></textarea>
              <input id="complaint-submit" class="form-button text-xs" type="submit" name="submit" value="Submit"/>
              </form>
            </div>



          </div>


          <div class="hidden" id="Office" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#maintenance"
                    role="tab">Office Maintenance  </button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#meeting"
                    role="tab">Meeting Room Booking </button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="maintenance" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Office Maintenance  </h2>
                  <p class="text-gray-400 mb-10">
                   
                    <li>	Please submit a ticket on TAM911 for any maintenance request or recommendation. </li>
<li> All requests will be evaluated based on budget, priority, and impact on the office working environment. </li>
<li>	We can not promise a deadline for these requests, as some can take 1 day and others could take a month. 
</li>
                  </p>
                </div>

              </div>

              <div class="hidden" id="meeting" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Training Budget</h2>
                  <p class="text-gray-400 mb-10">
                  To book a room in any of TAM offices, please take the following step: 
                  <li> Send an invitation to your meeting members and select the room. </li>
<li> Each room has a zoom code as follows: 
<li>	Riyadh 10th floor: </li>
<li>	Impact room: large meeting room: zoom #: https://tamhub.zoom.us/j/84535914251
Password: 511171 </li> </li>
 
                  </p>
                </div>

              </div>

              

            </div>
          </div>

          <div class="hidden" id="staffing" role="tabpanel">
          <div class="w-full intro-line">
              <h2>What is staffing?</h2>
              <p class="text-gray-400 mb-10">
              Staffing unit is responsible on managing the supply-demand of talents in TAM, whether they are full-time, contract-based, or part-time employees. Staffing unit is part of Professional Development (PD) team, and they are responsible on the following: 
              <li>Assigning & reassigning resources on engagements or internal projects</li>
              <li>Managing resources allocations and timesheet </li>
              <li>Running evaluation cycles and record TAM skills and capacities </li>
              </p>

              <h2>When should I go to staffing? </h2>
              <p class="text-gray-400 mb-10">
                <li>When you need resources in your engagement or team. </li>
                <li>When you are underutilized or overutilized in engagements</li>
              </p>
              <h2>What will staffing unit do after requesting resources?</h2>
              <p class="text-gray-400 mb-10">
                <li>Evaluate engagement or departmental budget to ensure there is an available budget for resource allocation. </li>
                <li>Understand the capabilities needed for the engagement </li>
                <li>Source talents to allocate on the engagement for short or long term</li>
                <li>Staffing will have access to talents from internal recruitment, current employees, and resourcing companies. </li>
              </p>
              <h2>What is staffing?</h2>
              <p class="text-gray-400 mb-10">
              </p>
              <h2>What information staffing unit needs for better resources management?</h2>
              <p class="text-gray-400 mb-10">
                <li><b>Timesheet:</b> staffing unit will start a practice of daily timesheet using an in-house developed platform. This is because knowing daily utilization will help in forecasting supply/demand</li>
                <li><b>Forecast needs:</b> business development will report frequently on estimations on the resources needs in future based on RFPs and client communications</li>
                <li><b>Project performance:</b> engagement managers will update their budgets and performance so staffing can tell which engagements will release talents to allocate on new engagements. </li>
              </p>
            </div>

        </div>

        <div class="hidden" id="leave" role="tabpanel">
          <div class="w-full intro-line">
             
              <p class="text-gray-400 mb-10">
                <li>All leave requests are through PalmHR. You can go to requests → Time Requests</li>
                <li>There are five types: 
                  <li>Vacation: a normal leave as per contract & saudi labor law</li>
                  <li>Sick leave: requires a medical report to approve </li>
                  <li>Unpaid leave: requires a reason for management to approve </li>
                  <li>Remote work: to report and record that you are working from home </li>
                  <li>Special leave: for training, death, maternity leave, and other special leave requests</li>
                </li>
             </p>
            </div>

        </div>
        <div class="hidden" id="r-letter" role="tabpanel">
          <div class="w-full intro-line">
             
              <p class="text-gray-400 mb-10">
              <li>
                I would like to receive a recommendation letter: 
                <li>Recommendation letters are direct between the employee and the recommender.</li>
              </li>
<li>
  I would like my letter to be stamped by TAM:
  <li>Please submit the letter to TAM911 once written and signed by the recommender.</li>
  <li>PD team will evaluate and sign if there are no concerns or contradictions with recorded evaluation in TAM records.</li>
</li>

             </p>
            </div>

        </div>
        <div class="hidden" id="issue-visa" role="tabpanel">
          <div class="w-full intro-line">
             
              <p class="text-gray-400 mb-10">
              This section is to illustrate the process of hiring a non-saudi living outside Saudi arabia. We can hire this type of candidates in two ways: 
              <li>
              For full-time hires (not contract-based) 
<li>Candidate has to be processed through Talent Acquisition team. </li>
<li>Talent acquisition team will develop an offer and share with candidate directly</li>
<li>TAM will not send an offer to a non-saudi candidate if there will be an issue with the visa or sponsorship or saudization. Thus, if offered is sent, then HR can handle it. </li>
<li>Visa processing is completely HR responsibility, TAM employees will not be involved in the process </li>

              </li>
              <li>
              For contract-based hires (not full-time employees) <br></br>
Two steps required for hiring a non-saudi living abroad to come to Saudi in a contract-based: first to get management approval, and then to issue a working visa. 
<br></br><br></br>
To request for a contract-based employee living abroad, <b>please send an email with the following information to talent acquisition team Boshra & Tatiana</b> with the following information: 
<li>Candidate email</li>
<li>CV </li>
<li>Partner approval </li>
<li>Contractual terms (in case discussed): salary, duration, scope </li>
<li>In case you have it, a university certificate. If not, HR will ask for it. </li>
<li>In case you have it, a passport copy. If not, HR will ask for it. </li>
<br></br><br></br>
The process: 
<li>TAM management approval based on budget and Saudi.gov regulations <a href="https://www.my.gov.sa/wps/portal/snp/servicesDirectory/servicedetails/8472">https://www.my.gov.sa/wps/portal/snp/servicesDirectory/servicedetails/8472</a> </li>
<li>HR will reach out to the candidate to sign the contract and requesting missing information</li>
<li>Once completed, HR will request for a working visa </li>
<li>One issued, HR will coordinate all logistics with the candidate to ensure a smooth experience to come to Saudi. </li>

              </li>
             </p>
            </div>

        </div>
</div>
        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>

      </div>
      <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
    </div>
  );
}

export default Human_Resources;
