import React from "react";
import ScriptTag from 'react-script-tag';
function Guidelines() {
  return (
    <div class="page-wrapper">
    <div class="header-wrapper">
        <div class="w-full intro-line">
            <img src="/assets/images/favicon.png" class="w-14"/>
            <h1>Design & Guidelines</h1>
        </div>
    </div>


    <div class="mb-4">
        <ul class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center" data-tabs-toggle="#MainTabs" id="mainTabsSelect" role="tablist">
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#teamguide"  role="tab" >Creative Team Guide</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#brief"  role="tab" >TAM Branding Brief</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#profile"  role="tab" >TAM Business Profile</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#folio"  role="tab" >TAM Creative Portfolio</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#reports"  role="tab" >TAM Designed Reports</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#fonts"  role="tab" >TAM Fonts</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#icons"  role="tab" >TAM Icons</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#logos"  role="tab" >TAM Logos</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#miniguide"  role="tab" >TAM Miniguideline</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button text-xs" data-tabs-target="#pres-temp"  role="tab" >TAM Presentations Templates</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button text-xs" data-tabs-target="#ppt"  role="tab" >TAM SM Posts Templates PPT</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button text-xs" data-tabs-target="#smsizes"  role="tab" >TAM SM Sizes Guidelines</button>
            </li>
            
            
            
        </ul>
    </div>


    <div class="section-wrapper">
        <div id="MainTabs">
            <div class="hidden" id="teamguide" role="tabpanel" >
                <h2>Creative Team Guide</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1XWQZiSPKUq2XNQEahnapbpiR4EiP-4jU/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Creative Team Guide.pdf</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="brief" role="tabpanel" >
                <h2>TAM Branding Brief</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1X_TLr1lqCu9UPURxcQXyGaau6dnXWhmV/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Branding Brief.pdf</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="profile" role="tabpanel" >
                <h2>TAM Business Profile</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/drive/folders/1_yJoceFdE96qbZMwR2eFzxzPGWOYp8tH" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Business Profile.pdf</p>
                    </a>
                    <a target="_blank" href="https://drive.google.com/drive/folders/1_xdJaxEm8MqXOrbcVCL-I6MaM1YCWKfY" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Business Profile.ppt </p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="folio" role="tabpanel" >
                <h2>TAM Creative Portfolio</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1XZT2Bw1ptXoh6eM-dB3u82zL2AkKACyv/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Creative Portfolio. pdf</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="reports" role="tabpanel" >
                <h2>TAM Designed Reports</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1XaWwohMnyiNcEPED0i3nTXTNDex-iBmd/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Content Report.pdf</p>
                    </a>
                    <a target="_blank" href="https://drive.google.com/file/d/1XcHsgcw1W3Lj1lqBEJ6PI-qvfrlQXaPr/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Crowdsourcing Report.pdf</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="fonts" role="tabpanel" >
                <h2>TAM Fonts</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1ZnrORqDo-eNpHCuSNKdfLUUgHio_c9iE/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Neo Sans Arabic - Bold</p>
                    </a>
                    <a target="_blank" href="https://drive.google.com/file/d/1ZnJyyN1BZLoX2ilEBOjZ_TAyslfpKzui/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Neo Sans Arabic - Light</p>
                    </a>
                    <a target="_blank" href="https://drive.google.com/file/d/1Zjhzn7w7XxmWn6SBP3roP0woKvADpZio/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Neo Sans Arabic - Medium</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="icons" role="tabpanel" >
                <h2>TAM Icons</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://docs.google.com/presentation/d/1TgIleeY75Jx-gNtOEiOSLJuC9DHXjB9e/edit?usp=sharing&ouid=115259872528103376108&rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Icons.pptx</p>
                    </a>
                    
                </div>
                </p>
            </div>

            <div class="hidden" id="logos" role="tabpanel" >
            <h2>TAM Logos</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1_04-P_K9g3PwO0yZZI3RTNvuH_QnUirh/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Logo.ai</p>
                    </a>
                    <a target="_blank" href="https://drive.google.com/file/d/1ZzUmNjKt_gb3sbwiWksC0R7WbCATw7P3/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Logo.pdf</p>
                    </a>
                    <a target="_blank" href="https://drive.google.com/drive/folders/1ZrILGgSHUIbKJDs_ztA30okqLyvroKqC" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Logo.png</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="miniguide" role="tabpanel" >
                <h2>TAM Miniguideline</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1XVCgR07yx0Z1sw4WtR4L23FdV5xkaGhP/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Brand Guidlines V05.pdf</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="pres-temp" role="tabpanel" >
                <h2>TAM Presentations Templates</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://docs.google.com/presentation/d/1QJs6Wov8tMG17MGf-OSGQA4AzxVMSwfs/edit?usp=sharing&ouid=115259872528103376108&rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Presentation Template Guide_Final_Arabic.pdf</p>
                    </a>
                    <a target="_blank" href="https://docs.google.com/presentation/d/16hEDujqy8hDfd9Xtpfra4GeWzt9zWLJu/edit?usp=sharing&ouid=115259872528103376108&rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">TAM Presentation Template Guide_Final_English.pdf</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="ppt" role="tabpanel" >
                <h2>TAM SM Posts Templates PPT</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://docs.google.com/presentation/d/1W8NznTkdCd2d2Pw1Ib3jm3PUEfd6FF91/edit?usp=sharing&ouid=115259872528103376108&rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Tam_Instagram_ImagePost.pptx</p>
                    </a>
                    <a target="_blank" href="https://docs.google.com/presentation/d/1W7z-4SGsxfE9RuLHBZfvyZosw0h3mHn1/edit?usp=sharing&ouid=115259872528103376108&rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Tam_Twitter_ImagePost.pptx</p>
                    </a>
                </div>
                </p>
            </div>

            <div class="hidden" id="smsizes" role="tabpanel" >
                <h2>TAM SM Sizes Guidelines</h2>
                <p class="text-gray-400 mb-10">
                <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                    <a target="_blank" href="https://drive.google.com/file/d/1XYwcWNxqjX7DOuNtpbH4k8qiMtpOGdH4/view?usp=sharing" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                        <img class="w-5" src="/assets/images/download-file.svg"></img>
                        <p class="mx-5">Tam_Sizes_Guidline_V2. pdf</p>
                    </a>
                </div>
                </p>
            </div>
            
            
            
            
        </div>

        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>
      
    </div>

    <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
</div>
  );
}

export default Guidelines;
