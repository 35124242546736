import React from "react";
import ScriptTag from 'react-script-tag';
function Agreemnts() {
  return (
    <div class="page-wrapper">
      <div class="header-wrapper">
        <div class="w-full intro-line">
          <img src="/assets/images/favicon.png" class="w-14"/>
          <h1>Corporate Agreements</h1>
        </div>
      </div>

      <div class="mb-4 ">
        <ul
          class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center"
          data-tabs-toggle="#MainTabs"
          id="mainTabsSelect"
          role="tablist">
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#hotels" role="tab">Hotels</button>
          </li>
          <li class="tab-element" role="presentation">
            <button
              class="tab-button text-xs"
              data-tabs-target="#transportations"
              role="tab">Transportations</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#translations" role="tab">Translations</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#researches" role="tab">Researches</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#food" role="tab">Food</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#banks" role="tab">Banks for Loans</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#resources" role="tab">Resources</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#services" role="tab">Services</button>
          </li>

        </ul>
      </div>

      <div class="section-wrapper">
        <div id="MainTabs">
          <div class="hidden" id="hotels" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#movenpick"
                    role="tab">Movenpick, Riyadh.</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#meridean"
                    role="tab">Le Meridean, Riyadh.</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#hilton"
                    role="tab">Hilton Garden Inn KAFD, Riyadh</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#hiltonr"
                    role="tab">Hilton Riyadh Hotel & Residences</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#radisson"
                    role="tab">Radisson Blu Hotel & Residence, Diplomatic Quarter, Riyadh.</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">

              <div class="hidden" id="movenpick" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Movenpick Hotel</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="https://goo.gl/maps/R5Y4y6iKyaTFwa7r8" target="_blank">Location</a>
                    </li>
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1CwOt31mQZHHovQVNr5tG1ttv3o5xUfee/view?usp=sharing"
                        target="_blank">Agreement download</a>
                    </li>
                    <li>The daily limit for all TAMers is 900 SAR per day.</li>
                    <li>The limit includes: (accommodation costs, breakfast, and laundry).</li>
                    <li>All other costs, even if it is within the limit, will be deducted from the
                      employee.</li>
                    <li>In case an employee incurred more than the limit, the difference will be
                      deducted from the employee.</li>
                    <li>Room Rates in SAR include Bed & Breakfast as below:</li>
                    <li>HOTEL RATES:
                      <li>Superior Room Single 800</li>
                      <li>Double 900</li>
                    </li>
                    <li>To book a room, please send it to the Reservations Dept. as following: 
                      <a href="mailto:hotel&residences.riyadh@movenpick.com"> hotel&residences.riyadh@movenpick.com </a><br></br>
                      & CC:
                      <a> mohamed.askri@movenpick.com </a>
                      with the following information:
                      <li>Mention TAM has a corporate agreement</li>
                      <li>Specify the number of nights and check-in/check-out dates</li>
                      <li>Specify if you’d like an early check-in or a late check-out</li>
                      <li>Ask to add in the invoice the project code (write project code here)</li>
                    </li>
                    <li>If the hotel is confirmed, go ahead and stay. If the hotel declined due to
                      vacancy, please book in another hotel.</li>
                    <li>If the hotel gave you a rate higher than the agreement rate, please send an
                      email to:
                      <a href="mohamed.askri@movenpick.com"> mohamed.askri@movenpick.com </a><br></br>
                      & CC:
                      <a> muna.sheikh@tamhub.com</a>
                    </li>
                    <li>When you book, you do not have to pay upon check-out</li>
                    <li>If they asked you to pay, please refer to the agreement & ask to meet Mr.
                      Mohammed Askri Phone No.
                      <a> 0569005537</a>
                    </li>
                  </p>
                </div>
              </div>
              <div class="hidden" id="meridean" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Le Meridean, Riyadh.</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="https://g.page/lemeridienriyadh?share" target="_blank">Location</a>
                    </li>
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1N1o5L52Ts02xEyjbD1XuqWmigKaVQn7N/view?usp=sharing"
                        target="_blank">Agreement download</a>
                    </li>
                    <li>The daily limit for all TAMers is 900 SAR per day.</li>
                    <li>The limit includes: (accommodation costs and breakfast).</li>
                    <li>All other costs, even if it is within the limit, will be deducted from the
                      employee.</li>
                    <li>In case an employee incurred more than the limit, the difference will be
                      deducted from the employee.</li>
                    <li>Room Rates in SAR include Bed & Breakfast as below:
                      <li>Deluxe room single 850.
                      </li>
                    </li>

                    <li>To book a room, please send it to the Reservations Dept. as following:
                      <a href=" cec.ruhmd@marriott-service.com"> cec.ruhmd@marriott-service.com
                      </a><br></br>
                      CC:
                      <a> faris.alghorabi@marriott.com </a>
                      &
                      <a> muna.sheikh@tamhub.com </a>
                      with the following information:
                      <li>Mention TAM has a corporate agreement</li>
                      <li>Specify the number of nights and check-in/check-out dates</li>
                      <li>Specify if you’d like an early check-in or a late check-out</li>
                      <li>Ask to add in the invoice the project code (write project code here)</li>
                    </li>
                    <li>If the hotel is confirmed, go ahead and stay. If the hotel declined due to
                      vacancy, please book in another hotel.</li>
                    <li>If the hotel gave you a rate higher than the agreement rate, please send an
                      email to:
                      <a href="faris.alghorabi@marriott.com"> faris.alghorabi@marriott.com
                      </a><br></br>
                      & CC:
                      <a> muna.sheikh@tamhub.com</a>
                    </li>
                    <li>When you book, you do not have to pay upon check-out</li>
                    <li>If they asked you to pay, please refer to the agreement & ask to meet Mr
                      Faris Al Ghorabi Phone No.
                      <a> 0569005537 </a>
                      or Mr.Wael Islambouli Phone No.
                      <a> 054887053 </a>
                    </li>
                  </p>
                </div>
              </div>
              <div class="hidden" id="hilton" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Hilton Garden Inn Riyadh Financial District, Riyadh</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="https://g.page/HiltonGardenInnRiyadh?share" target="_blank">Location</a>
                    </li>
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1-bADNWBXyHLAuMxtgZvOZmHgXsXyvwtY/view?usp=sharing"
                        target="_blank">Agreement download</a>
                    </li>
                    <li>The daily limit for all TAMers is 900 SAR per day.</li>
                    <li>Limit includes: (accommodation cost, breakfast, and laundry).</li>
                    <li>All other costs, even if it is within the limit, will be deducted from the
                      employee.</li>
                    <li>In case an employee incurred more than the limit, the difference will be
                      deducted from the employee.</li>
                    <li>Room Rates in SAR include Bed & Breakfast as below:
                      <li>King Rooms Single 550. Double 625
                      </li>
                      <li>King Rooms City View Single 700. Double 775</li>
                    </li>

                    <li>To book a room, please send it to the Reservations Dept. as following:
                      <a href=" RUHSK_RES@hilton.com"> RUHSK_RES@hilton.com </a><br></br>
                      CC:
                      <a> ahmed.shaaban@hilton.com </a>
                      with the following information:
                      <li>Mention TAM has a corporate agreement</li>
                      <li>Specify the number of nights and check-in/check-out dates</li>
                      <li>Specify if you’d like an early check-in or a late check-out</li>
                      <li>Ask to add in the invoice the project code (write project code here)</li>
                    </li>
                    <li>If the hotel is confirmed, go ahead and stay. If the hotel declined due to
                      vacancy, please book in another hotel.</li>
                    <li>If the hotel gave you a rate higher than the agreement rate, please send an
                      email to:
                      <a href="ahmed.shaaban@hilton.com"> ahmed.shaaban@hilton.com </a><br></br>
                      & Cc:
                      <a> muna.sheikh@tamhub.com </a>
                    </li>
                    <li>When you book, you do not have to pay upon check-out</li>
                    <li>If they asked you to pay, please refer to the agreement & ask to meet Mr
                      Ahmed Shaaban Phone No.
                      <a> 0547143247 </a>
                    </li>
                  </p>
                </div>
              </div>
              <div class="hidden" id="hiltonr" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Hilton Garden Inn Riyadh Financial District, Riyadh</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="https://g.page/HiltonRiyadh?share" target="_blank">Location</a>
                    </li>
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1Tc7-KoZ_SyptzI8R2wuUuhQFr4ffM-iW/view?usp=sharing"
                        target="_blank">Agreement download</a>
                    </li>
                    <li>The daily limit for all TAMers is 900 SAR per day.</li>
                    <li>Limit includes: (accommodation cost, breakfast, and laundry).</li>
                    <li>All other costs, even if it is within the limit, will be deducted from the
                      employee.</li>
                    <li>In case an employee incurred more than the limit, the difference will be
                      deducted from the employee.</li>
                    <li>Room Rates in SAR include Bed & Breakfast as below:
                      <li>Guest room single 550. Double 650
                      </li>
                      <li>Deluxe room single 650. Double 750</li>
                    </li>

                    <li>To book a room, please send it to the Reservations Dept. as following:
                      <a href="Ruhch_res@hilton.com"> Ruhch_res@hilton.com </a><br></br>
                      CC:
                      <a> Ahmed.shaer@hilton.com </a>
                      with the following information:
                      <li>Mention TAM has a corporate agreement</li>
                      <li>Specify the number of nights and check-in/check-out dates</li>
                      <li>Specify if you’d like an early check-in or a late check-out</li>
                      <li>Ask to add in the invoice the project code (write project code here)</li>
                    </li>
                    <li>If the hotel is confirmed, go ahead and stay. If the hotel declined due to
                      vacancy, please book in another hotel.</li>
                    <li>If the hotel gave you a rate higher than the agreement rate, please send an
                      email to:
                      <a href="Ahmed.shaer@hilton.com"> Ahmed.shaer@hilton.com </a><br></br>
                      & Cc:
                      <a> muna.sheikh@tamhub.com </a>
                    </li>
                    <li>When you book, you do not have to pay upon check-out</li>
                    <li>If they asked you to pay, please refer to the agreement & ask to meet Mr.
                      Ahmed Alshaer Phone No.
                      <a> 0535171864 </a>
                    </li>
                  </p>
                </div>
              </div>
              <div class="hidden" id="radisson" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Radisson Blu Hotel & Residence, Diplomatic Quarter, Riyadh.</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="https://g.page/RadBluDQRiyadh?share" target="_blank">Location</a>
                    </li>
                    <li>
                      <a href="" target="_blank">Agreement download</a>
                    </li>
                    <li>The daily limit for all TAMers is 900 SAR per day.</li>
                    <li>Limit includes: (accommodation cost, breakfast, and laundry).</li>
                    <li>All other costs, even if it is within the limit, will be deducted from the
                      employee.</li>
                    <li>In case an employee incurred more than the limit, the difference will be
                      deducted from the employee.</li>
                    <li>Room Rates in SAR include Bed & Breakfast as below:
                      <li>Standard Room 850.</li>

                    </li>

                    <li>To book a room, please send it to the Reservations Dept. as following:
                      <a href="reservations.dq.riyadh@radissonblu.com"> reservations.dq.riyadh@radissonblu.com </a><br></br>
                      CC:
                      <a> Bayan.shaheen@radissonblu.com </a>
                      with the following information:
                      <li>Mention TAM has a corporate agreement</li>
                      <li>Specify the number of nights and check-in/check-out dates</li>
                      <li>Specify if you’d like an early check-in or a late check-out</li>
                      <li>Ask to add in the invoice the project code (write project code here)</li>
                    </li>
                    <li>If the hotel is confirmed, go ahead and stay. If the hotel declined due to
                      vacancy, please book in another hotel.</li>
                    <li>If the hotel gave you a rate higher than the agreement rate, please send an
                      email to:
                      <a href="Bayan.shaheen@radissonblu.com"> Bayan.shaheen@radissonblu.com </a><br></br>
                      & Cc:
                      <a> muna.sheikh@tamhub.com </a>
                    </li>
                    <li>When you book, you do not have to pay upon check-out</li>
                    <li>If they asked you to pay, please refer to the agreement & ask to meet Ms
                      Bayan Shaheen Phone No.
                      <a> 0544353366 </a>
                    </li>
                  </p>
                </div>
              </div>

            </div>

          </div>

          <div class="hidden" id="transportations" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#car-rental"
                    role="tab">Car Rental</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#car-ride"
                    role="tab">Car Rides</button>
                </li>

              </ul>
            </div>

            <div id="InnerTabs1">

              <div class="hidden" id="car-rental" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Theeb</h2>
                  <p class="text-gray-400">
                    Renting a car is only eligible for heads and above. All other levels can use
                    transportation apps.
                    <br></br>
                    <br></br>
                    You can book through their website
                    <a href="https://www.theebonline.com/en/home/"> Here</a>, Or you can call them at
                    Alzubair Alnoor at +966569246001, Ghassan: +966549100069 And Mahmoud Khalid at
                    +966541176814
                    <br></br>
                    <br></br>
                    To know the list of cars and daily rate, please download the agreement below. In
                    Riyadh, Theeb has multiple locations:
                    <li>
                      <a href="https://maps.app.goo.gl/6fg449X6LkBizeRQ7?g_st=ic">The closest to TAM office</a>
                    </li>
                    <li>
                      <a href="https://maps.app.goo.gl/fPLXT8WRUGsn9ZCaA?g_st=ic">Airport</a>
                    </li>
                    <li>TAM Account Number: 106211</li>
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1vn3WEiUIW0uuNGXADyI-FvSh1bBWR6NO/view?usp=sharing">The Agreement</a>
                    </li>
                    <li>Please download the
                      <a href=""> PDF </a>
                      for details on limits per level.
                    </li>
                    <br></br>
                    <br></br>
                  </p>
                  <h2>How to Book</h2>
                  <p class="text-gray-400">
                    Please download the reservation form from
                    <a
                      href="https://docs.google.com/document/d/1Y8_5BQcZJ4o9NBXz8wLO18yoDgwHZ9cn/edit?usp=sharing&ouid=113207431789047485577&rtpof=true&sd=true"> Here </a>
                    and fill out the details. Pls, don’t forget to mention TAM’s Account number
                    above. then send it to the reservations team in the needed region as the
                    following:
                    <li>Reservation requests for the central and northern region:<a href="mailto:reserveryd@theeb.sa">
                        reserveryd@theeb.sa</a>
                    </li>
                    <li>Reservation requests Eastern region:<a href="mailto:reservedmm@theeb.sa"> reservedmm@theeb.sa</a>
                    </li>
                    <li>Reservation requests Western and Southern region:<a href="mailto:reservejed@theeb.sa"> reservejed@theeb.sa</a>
                    </li>
                    <li>Reservations Supervisor Mr Mohamed - Mobile<a href="tel:0549100068"> 0549100068</a>
                    </li>
                    <br></br>
                    <br></br>
                    If Theeb confirmed your car reservation, go ahead and use it. If not, you can
                    book with another company or use a transportation app.
                    <br></br>
                    <br></br>
                    If Theeb gave you a rate higher than what is agreed, please contact the
                    reservation Supervisor, Mr Mohamed - Mobile 0549100068
                    <br></br>
                    <br></br>
                  </p>
                  <h2>Instructions</h2>
                  <p class="text-gray-400">
                    <li>Partner or Project Manager’s approval is needed before using this service.</li>
                    <li>TAM will cover the economy basic car category in the App.</li>
                    <li>Every month, the partner will review all expenses and confirm/comment on the
                      list.</li>
                    <li>In case there was a comment on your expense, your partner will connect with you.
                    </li>
                    <li>All Careem expenses that are not project-based, or allocated on a project,
                      will be deducted from the employee.
                    </li>
                    <li>If (TAM) didn’t appear in the payment method options pls, contact:
                      <a href="mailto:muna.sheikh@tamhub.com"> muna.sheikh@tamhub.com </a><br></br>CC: <a href="mailto:mawadda.abukhamis@careem.com"> mawadda.abukhamis@careem.com </a>
                    </li>
                  </p>
                </div>
              </div>
              <div class="hidden" id="car-ride" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Careem</h2>
                  <p class="text-gray-400 mb-10">
                    TAM signed an agreement with Careem to save you from the operational hussle of
                    reimbursements.
                  </p>
                  <h2>How to Book</h2>
                  <p class="text-gray-400 mb-10">
                    Please go to Careem app
                    <a href="https://apps.apple.com/us/app/careem-ride-delivery-pay/id592978487">
                      IOS</a>,<a
                        href="https://play.google.com/store/apps/details?id=com.careem.acma&hl=ar&gl=US">
                      Android</a>, log in using your Number, then book a ride with the needed
locations, and when choosing the payment method, you will find those
oppositions: Cash - Apple pay - TAM select TAM Then make sure to add the project
code or name in the trip.
                    <br></br>
                    this step is very important. Once you do that, TAM will be billed directly.
                    <br></br>
                    <br></br>
                    Every month, the partner will review all expenses and confirm/comment on the
                    list.
                    <br></br>
                    <br></br>
                    In case there was a comment on your expense, your partner will connect with you.
                    <br></br>
                    <br></br>
                    All Careem expenses that are not project-based, or allocated on a project, will
                    be deducted from the employee.

                  </p>
                  <h2>Instructions</h2>
                  <p class="text-gray-400">
                    <li>Partner or Project Manager’s approval is needed before using this service.</li>
                    <li>TAM will cover the economy basic car category in the App.</li>
                    <li>Every month, the partner will review all expenses and confirm/comment on the
                      list.</li>
                    <li>In case there was a comment on your expense, your partner will connect with you.
                    </li>
                    <li>All Careem expenses that are not project-based, or allocated on a project,
                      will be deducted from the employee.
                    </li>
                    <li>If (TAM) didn’t appear in the payment method options pls, contact:
                      <a href="mailto:muna.sheikh@tamhub.com"> muna.sheikh@tamhub.com </a>Cc:<br></br>
                      <a href="mailto:mawadda.abukhamis@careem.com"> mawadda.abukhamis@careem.com 
                      </a>
                    </li>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="hidden" id="translations" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#ez"
                    role="tab">
                    Ez Translations</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#surv"
                    role="tab">SURV Translation</button>
                </li>

              </ul>
            </div>

            <div id="InnerTabs1">

              <div class="hidden" id="ez" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>
                    Ez Translations</h2>
                  <p class="text-gray-400 mb-10">
                    <li>
                      <a href="https://www.ez.works/">Website</a>
                    </li>
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1eChtYhFCamIj5us1cIgK6C0S6IEEodr5/view?usp=sharing">Agreement Download
                      </a>
                    </li>
                    <li>Services:
                      <br></br>
                      Ez works is the Suite of Business Support Services for Presentation design
                      Services, Language Translation, Audio-Visual Production Services, and Graphic
                      Design</li>

                    <li>
                      <a
                        href="https://drive.google.com/file/d/1BKZu60Pv7I4f1IsXYf4e4QDaYaLVp3Gm/view?usp=sharing">Work Samples</a>
                    </li>
                    <li>How To request the services:<br></br>
                      First, take approval for using the services from the authorised individuals
                      (director and above). Then, please send a detailed request to: <a href="mailto:support@ez.works"> support@ez.works </a>
                      <br></br>CC: <a href="mailto:deepali.beniwal@ezworks.io"> deepali.beniwal@ezworks.io </a> also, CC your partner for approvals.
                    </li>
                    <li>You do not have to submit a payment request for the work made. EZ will bill
                      TAM directly.
                    </li>
                  </p>

                  <h2>Basic / Projects</h2><hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                    <div class="flex justify-between flex-wrap gap-4">

                      <div class="service text-center">
                        <p class="font-bold mb-2">Number of<br></br>Slides</p>
                        <p>0-100</p>
                        <p>101-200</p>
                        <p>201 or more</p>
                      </div>

                      <div class="discount text-center">
                        <p class="font-bold mb-2">Discount as per the volume on <br></br>our Adhoc rate AED 30/slide</p>
                        <p>10%</p>
                        <p>15%</p>
                        <p>20%</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Price/Slide<br></br>after Discount</p>
                        <p>AED 27.00</p>
                        <p>AED 25.50</p>
                        <p>AED 27.00</p>
                      </div>

                      <div class="time-est text-center">
                        <p class="font-bold mb-2">Estimated<br></br>Time</p>
                        <p>5 days</p>
                        <p>8 - 10 days</p>
                        <p>12 - 14 days</p>
                      </div>
                      
                    </div> 
                  </p>
                  
                  <h2>Advance / Projects</h2><hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                    <div class="flex justify-between flex-wrap gap-4">

                      <div class="service text-center">
                        <p class="font-bold mb-2">Number of<br></br>Slides</p>
                        <p>0-100</p>
                        <p>101-200</p>
                        <p>201 or more</p>
                      </div>

                      <div class="discount text-center">
                        <p class="font-bold mb-2">Discount as per the volume on <br></br>our Adhoc rate AED 30/slide</p>
                        <p>10%</p>
                        <p>15%</p>
                        <p>20%</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Price/Slide<br></br>after Discount</p>
                        <p>AED 54.00</p>
                        <p>AED 51.50</p>
                        <p>AED 48.00</p>
                      </div>

                      <div class="time-est text-center">
                        <p class="font-bold mb-2">Estimated<br></br>Time</p>
                        <p>8 days</p>
                        <p>12 - 14 days</p>
                        <p>17 - 19 days</p>
                      </div>
                    </div> 
                  </p>
                  <hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                    <div class="flex justify-between flex-wrap gap-4">

                      <div class="service text-center">
                        <p class="font-bold mb-2">Number</p>
                        <p>1</p>
                        <p>2</p>
                      </div>

                      <div class="discount text-center">
                        <p class="font-bold mb-2">Service line</p>
                        <p>Translation Service</p>
                        <p>Data Processing</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Offering</p>
                        <p>EZ Assured</p>
                        <p>Data Visualization</p>
                      </div>

                      <div class="time-est text-center">
                        <p class="font-bold mb-2">List Price</p>
                        <p>AED 0.75/word</p>
                        <p>AED 368.00/word</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Corporate Discount 15%</p>
                        <p>AED 0.637/word</p>
                        <p>AED 312 /word</p>
                      </div>

                    </div> 
                  </p>

                  <h2 class="mt-5">Discounts on Invoicing</h2><hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                  <p>AED 25K – AED 50K.  |  Additional 5% Discount on the total invoice value</p>
                  <p>AED 50K.  |  Additional 10% Discount on the total invoice value</p>
                  </p>

                </div>
              </div>
              <div class="hidden" id="surv" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>
                    SURV Translation</h2>

                  <p class="text-gray-400 mb-10">
                    <li>
                      <a href="">Website</a>
                    </li>
                    <li>
                      <a href="">Agreement Download
                      </a>
                    </li>
                    <li>Services:
                      <br></br>
                      Translation, Arabic, English, Public Sector, Consulting, Banking, Strategy,
                      Finance, Business, Writing, Education, Urban Development, Legal,
                    </li>
                    <li>How To request the services:
                      <br></br>
                      First, take approval for using the services from the authorized individuals
                      (director and above). after taking the approval pls, follow the steps below and
                      also CC your partner for approvals.
                    </li>
                    <li>Information needed when booking:<br></br>
                      Ahead of time (24-48 hours in advance), Number of slides, Dropoff time,
                      Deadline.

                    </li>
                    <li>When the document is ready, kindly share it with the emails below, along
                      with specific instructions – if any.:
                      <a href="mailto:stephanie@surv-translation.com"> stephanie@surv-translation.com</a> , <a href="mailto:Sahar@surv-translation.com"> sahar@surv-translation.com</a> , <a href="mailto:translation@surv-translation.com"> translation@surv-translation.com</a>.

                    </li>
                    <li>Surv Translation working hours: 7 days/week from 7 am. till 7 pm
                    </li>
                    <li>You do not have to submit a payment request for the work made. SURV will
                      bill TAM directly. 
                    </li>
                    <li>Work Timeline:
                      <li>
                        Less than 1,000 words Same day.
                      </li>
                      <li>1,000 words – 2,500 words 24 hours.</li>
                      <li>2,500 words – 5,000 words 48 hours.
                      </li>
                      <li>More than 5,000 48 – 72 hours (If the needed capacity is booked 48 hours
                        ahead of time, they can deliver the project faster).
                      </li>
                    </li>

                  </p>

                  <h2>SURV Translation Rates</h2><hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p class="font-bold mb-2">Services</p>
                        <p>High-end Translation<br></br>High-quality deliverables support coveringtranslation, editing and proofreading (see below) – for meetings, publishing, and high-exposure documents (3 layers) </p>
                      </div>

                      <div class="price">
                        <p class="font-bold mb-2">Price</p>
                        <p>0.144 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>
                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Editing<br></br>			
                        Comparing source document to target document and performing required quality assurance (2 layers)</p>
                      </div>

                      <div class="price">
                        <p>0.096 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>
                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Proofreading<br></br>Reading the target document only and performing required quality assurance (1 layer)</p>
                      </div>

                      <div class="price">
                        <p>0.048 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Content Creation<br></br>Producing impactful and engaging texts to create fetching content (content writing, social media content writing, copywriting, content summarization, etc.)</p>
                      </div>

                      <div class="price">
                        <p>0.192 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Design Switching<br></br>Design material to align it with the target language (e.g., slide switching)</p>
                      </div>

                      <div class="price">
                        <p>1.6 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>


                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Translation for Internal Use<br></br>
                        Translation with a quick review layer – for internal usage, low exposure documents (2 layers)</p>
                      </div>

                      <div class="price">
                        <p>0.96 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Typing<br></br>
                        Typing text from image to Microsoft Office files (e.g., Microsoft Word, PowerPoint, etc.)</p>
                      </div>

                      <div class="price">
                        <p>0.024 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Subtitling<br></br>
                        Displaying a written text of spoken dialogue on screen, simultaneously with the audio</p>
                      </div>

                      <div class="price">
                        <p>16 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Audio Transcription<br></br>
                        Converting audio recordings to text</p>
                      </div>

                      <div class="price">
                        <p>3.6 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Express Fees for Translation<br></br>
                        Applied when the required number of words for translation exceeds 2,500 words per day (OR 150 words per hour) and for overnight requests</p>
                      </div>

                      <div class="price">
                        <p>30% additional fees</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Remote Linguistic Support for Meetings<br></br>
                        Linguistic support for content development, copywriting or slide creation over an online platform such as Zoom</p>
                      </div>

                      <div class="price">
                        <p>80 USD/hour</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Remote Linguistic Support – Per Diem<br></br>
                        Dedicating a Linguist to support with the translation or content creation over a specific durationUnit price before discount</p>
                      </div>

                      <div class="price">
                        <p>480 USD/day</p>
                      </div>
                    </div> 

                  </p>

                </div>
              </div>
            </div>
          </div>

          <div class="hidden" id="researches"  role="tabpanel">
            <div class="w-full intro-line mt-10">
              <h2>Gulf Researcher</h2>
              <p class="text-gray-400">
                <li>
                  <a target="_blank" href="https://gulfresearcher.com">Website</a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://drive.google.com/file/d/1vM0VQtt3Yw6YGqeWq4toEtb08QuVhL7S/view?usp=sharing">Agreement Download</a>
                </li>
                <li>Services:
                  <br></br>
                  They provide on-demand research in the form of benchmarking, whitepapers, or
                  general research on diverse topics. Also, they provide it within three days or
                  24 hours with a rushed fee.
                </li>
                <li>Services rate: (valid until the end of 2022)

                  <li>Normal requests: $85 per hour
                  </li>
                  <li>Urgent Rushed requests: $105 per hour</li>
                </li>
                <li>How To request the services:<br></br>Make sure you have an approved budget by a partner.
                </li>
                <li>Submit a detailed request to:
                  <a href="mailto:malsaleh@gulfresearcher.com ">
                    malsaleh@gulfresearcher.com
                  </a>
                  & CC: your partner for approval.<br></br>
                  Please download the link below to have a template of the request.
                </li>
                <li>Background:
                  <li>Please share the context of the work you need to research for.
                  </li>
                  <li>
                    Please share the importance of the research in one statement.
                  </li>
                  <li>Share at least three objectives of the research project:<br></br>
                    01.
                    <br></br>
                    02.<br></br>
                    03.
                  </li>

                </li>
                <li>Research SOW:
                  <li>Identify the demographic of the research (countries, focus groups, or other
                    demographics)
                  </li>
                  <li>Identify specific domains in the research you’d like to focus on
                  </li>
                  <li>Identify key questions you are hoping to understand or the research answers
                  </li>
                </li>
                <li>Timeline:<br></br>
                  Please always give 2 to 4 days for benchmarking. If it is rushed, please
                  highlight it is rushed, and you should expect it in less than 24hrs.
                </li>

              </p>

            </div>
          </div>

          <div class="hidden" id="food" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#yumaima"
                    role="tab">
                    Yumaima</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#shape"
                    role="tab">V-shape</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#calo"
                    role="tab">CALO</button>
                </li>

              </ul>
            </div>

            <div id="InnerTabs1">

              <div class="hidden" id="yumaima" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>Yumaima</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="https://www.instagram.com/yumamiafood/">Website
                      </a>
                    </li>
                    <li>
                      <a href="https://apps.apple.com/sa/app/yumamia/id1631875764">IOS App
                      </a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.rumbl.yumamia">Android App
                      </a>
                    </li>
                    <li>
                      <a href="https://www.yumamia.com/menus">Menu
                      </a>
                    </li>
                    <br></br>
                    To use this service pls, download the App from, create an account if you don’t
                    have one already and when you’re done choosing the meal and fill out all
                    information needed simply apply TAM code and enjoy the %15 discount till
                    31\09\2022.
                  </p>

                </div>
              </div>
              <div class="hidden" id="shape" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>V-shape</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="https://www.instagram.com/v.shape.sa/">Website
                      </a>
                    </li>
                    <li>
                      <a href="https://apps.apple.com/sa/app/yumamia/id1631875764">IOS App
                      </a>
                    </li>
                    <li>How to order:

                    </li>
                    Order Via Whatsapp: 0540481010 – 0563555394
                    <li>
                      <a href="https://drive.google.com/open?id=1aN4RvObDDvW_htD-Ri9vrJakHmeBcV8K">Menu
                      </a>
                    </li>
                    <br></br>
                    To use this service pls, contact them on WhatsApp and provide them with your
                    request.
                  </p>

                </div>
              </div>
              <div class="hidden" id="calo" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <h2>CALO</h2>
                  <p class="text-gray-400">
                    <li>
                      <a href="">Website
                      </a>
                    </li>
                    <li>
                      <a href="">IOS App
                      </a>
                    </li>
                    <li>
                      <a href="">Android App
                      </a>
                    </li>
                    <li>How to order:

                    </li>
                    To use this service pls, download the App link above create an account if you
                    don’t have one already and when you’re done choosing the meal and fill out all
                    information needed simply apply TAM code and enjoy the %15 discount till
                    31\09\2022.
                  </p>

                </div>
              </div>
            </div>
          </div>

          <div class="hidden" id="banks" role="tabpanel">
          <div class="w-full intro-line">
              <h2>TAM Loan</h2>
              <p class="text-gray-400 mb-10">
                TAM signed with multiple banks to facilitate loans for all employees. To take a
                loan, please connect directly with one of the following banks:
                <li>AlRajhi bank</li>
                <li>AlJAzira bank</li>
                <li>Emirates NBD bank</li>
                <br></br>

                For all loan requests, banks might request one or more of the following:
                <li>Employee letter: You can request it from PalmHR</li>
                <li>Transfer salary to the bank: You can request it from PalmHR</li>
                <br></br>
                For non-saudis, banks have different regulations, please connect with the bank
                for further details.
              </p>
            </div>
          </div>

          <div class="hidden" id="resources" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#glg"
                    role="tab">
                    GLG</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#comatch"
                    role="tab">CoMatch</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#makeen"
                    role="tab">Makeen Advisors</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#guidepoint"
                    role="tab">GuidePoint</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#glassford"
                    role="tab">Glassford</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#huxly"
                    role="tab">Huxly</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#strathaus"
                    role="tab">Strathaus</button>
                </li>

              </ul>
            </div>

            <div id="InnerTabs1">

              <div class="hidden" id="glg" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit the website <a href="https://glginsights.com/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Michael Warner Email: <a href="mailto:mwarner@glgroup.com">mwarner@glgroup.com</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, GLG will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>GLG charges For each telephone consultation per hour at a rate of USD $1,350</li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to GLG and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>GLG will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="comatch" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit CoMatch website to know more about their services  <a href="https://www.comatch.com/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Clement Eymard <a href="mailto:c.eymard@comatch.com">c.eymard@comatch.com</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, CoMatch  will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>Please share an estimated budget with the request, and they will try to match your needs. </li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to CoMatch and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>CoMatch will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="makeen" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit Makeen website for further details <a href="https://makeenadvisors.com/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Ziad Soufan <a href="mailto:ziad.soufan@makeenadvisors.com">ziad.soufan@makeenadvisors.com</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, they will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>Makeen Advisors charges For per day 2,000 USD</li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Makeen will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="guidepoint" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit Guidepoint website for further details <a href="https://www.guidepoint.com/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Bilal Ashraf Email: <a href="mailto:bsabouni@guidepoint.com">bsabouni@guidepoint.com</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, they will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>Guidepoint provide Client with access to units at a rate of $1,175.00 per unit.</li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Guidepoint  will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="glassford" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit Glassford website for further details <a href="http://glassford.global/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Ivan Kovganov Email: <a href="mailto:ivan@glassford.global">ivan@glassford.global</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, they will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>Check Glassford Rate chart <a href="https://drive.google.com/file/d/112CK4mF57ZyCaPdkP9q0s59gi5FOv2LR/view">here</a></li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Glassford  will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="huxly" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit Huxly website for further details <a href="https://www.huxley.com/en-ae/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Miona Jovic Email: <a href="mailto:m.jovic@huxley.com">m.jovic@huxley.com</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, they will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>Please share the estimated budget when you send a request, and they will try to match your needs. </li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Huxly will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="strathaus" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit Strathus website for further details <a href="https://www.strathaus.co/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Ralp Bounassif <a href="mailto:ralph.bounassif@strathaus.co">ralph.bounassif@strathaus.co</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, they will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>Rates depend on level and duration requested. Please request a cost estimation from them</li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Strathus will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              
            </div>
          </div>
          <div class="hidden" id="services" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1"
                id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#gulf"
                    role="tab">
                    Gulf Researcher</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#surv-t"
                    role="tab">SURV Translation </button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#ez-works"
                    role="tab">Ez Works</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#prezlab"
                    role="tab">PrezLab</button>
                </li>
                

              </ul>
            </div>

            <div id="InnerTabs1">

              <div class="hidden" id="gulf" role="tabpanel">
                <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit Gulf Researcher website for further details  <a href="https://www.gulfresearcher.com">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: <a href="mailto:malsaleh@gulfresearcher.com">malsaleh@gulfresearcher.com</a></li>
  <li>Please loop your partner for approval</li>
  <li>Upon your request, GLG will submit different SME profiles for your review</li>
  <li>Upon selecting a profile, you can go ahead and work with the SME</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>Gulf Researcher charges for Normal requests 85 USD per hour  and for urgent requests 105 USD per hour</li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Gulf Researchers will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
  <br></br>
  Request template: 
<li>
  <b>Background: </b>
  Please share the context of the work you need to research for.
  Please share the importance of the research in one statement.
  Share at least three objectives of the research project:
  <li>1</li>
  <li>2</li>
  <li>3</li>
</li>
 
<li>
  <b>Research SOW:</b>
  <li>Identify the demographic of the research (countries, focus groups, or other demographics)</li>
  <li>Identify specific domains in the research you’d like to focus on</li>
  <li>Identify key questions you are hoping to understand or the research answers</li>
  
</li>
<li>
  <b>Timeline:</b>
  Please always give 2 to 4 days for benchmarking. If it is rushed, please highlight it is rushed, and you should expect it in less than 24hrs.
</li>

</p>

                  

                </div>
              </div>
              <div class="hidden" id="surv-t" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit SURV Translation website for further details <a href="https://www.linkedin.com/company/surv-translation/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: <a href="mailto:stephanie@surv-translation.com">stephanie@surv-translation.com</a>, <a href="mailto:sahar@surv-translation.com">sahar@surv-translation.com</a>, <a href="mailto:translation@surv-translation.com">translation@surv-translation.com</a></li>
  <li>Please loop your partner for approval</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>
<h2>Work timeline</h2>
<p className="text-gray-400 mb-8">
<li>Less than 1,000 words Same day.</li><li>1,000 words – 2,500 words 24 hours.</li><li>2,500 words – 5,000 words 48 hours.</li><li>More than 5,000 48 – 72 hours (If the needed capacity is booked 48 hours ahead of time, they can deliver the project faster).</li>
</p>

<h2>What are the rates? </h2>
<p class="text-gray-400 mt-2 mb-10">

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p class="font-bold mb-2">Services</p>
                        <p>High-end Translation<br></br>High-quality deliverables support coveringtranslation, editing and proofreading (see below) – for meetings, publishing, and high-exposure documents (3 layers) </p>
                      </div>

                      <div class="price">
                        <p class="font-bold mb-2">Price</p>
                        <p>0.144 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>
                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Editing<br></br>			
                        Comparing source document to target document and performing required quality assurance (2 layers)</p>
                      </div>

                      <div class="price">
                        <p>0.096 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>
                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Proofreading<br></br>Reading the target document only and performing required quality assurance (1 layer)</p>
                      </div>

                      <div class="price">
                        <p>0.048 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Content Creation<br></br>Producing impactful and engaging texts to create fetching content (content writing, social media content writing, copywriting, content summarization, etc.)</p>
                      </div>

                      <div class="price">
                        <p>0.192 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Design Switching<br></br>Design material to align it with the target language (e.g., slide switching)</p>
                      </div>

                      <div class="price">
                        <p>1.6 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>


                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Translation for Internal Use<br></br>
                        Translation with a quick review layer – for internal usage, low exposure documents (2 layers)</p>
                      </div>

                      <div class="price">
                        <p>0.96 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Typing<br></br>
                        Typing text from image to Microsoft Office files (e.g., Microsoft Word, PowerPoint, etc.)</p>
                      </div>

                      <div class="price">
                        <p>0.024 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Subtitling<br></br>
                        Displaying a written text of spoken dialogue on screen, simultaneously with the audio</p>
                      </div>

                      <div class="price">
                        <p>16 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Audio Transcription<br></br>
                        Converting audio recordings to text</p>
                      </div>

                      <div class="price">
                        <p>3.6 USD/word</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Express Fees for Translation<br></br>
                        Applied when the required number of words for translation exceeds 2,500 words per day (OR 150 words per hour) and for overnight requests</p>
                      </div>

                      <div class="price">
                        <p>30% additional fees</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Remote Linguistic Support for Meetings<br></br>
                        Linguistic support for content development, copywriting or slide creation over an online platform such as Zoom</p>
                      </div>

                      <div class="price">
                        <p>80 USD/hour</p>
                      </div>
                    </div> 
                    <hr></hr>

                    <div class="flex justify-between flex-wrap gap-4 my-4">
                      <div class="service max-w-[500px]">
                        <p>Remote Linguistic Support – Per Diem<br></br>
                        Dedicating a Linguist to support with the translation or content creation over a specific durationUnit price before discount</p>
                      </div>

                      <div class="price">
                        <p>480 USD/day</p>
                      </div>
                    </div> 

                  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to CoMatch and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>SURV will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="ez-works" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit Ez Works website for further details <a href="https://www.ez.works/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: <a href="mailto:support@ez.works">support@ez.works</a> Cc: <a href="mailto:deepali.beniwal@ezworks.io">deepali.beniwal@ezworks.io</a>  </li>
  <li>Please loop your partner for approval</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<h3>Basic / Projects</h3><hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                    <div class="flex justify-between flex-wrap gap-4">

                      <div class="service text-center">
                        <p class="font-bold mb-2">Number of<br></br>Slides</p>
                        <p>0-100</p>
                        <p>101-200</p>
                        <p>201 or more</p>
                      </div>

                      <div class="discount text-center">
                        <p class="font-bold mb-2">Discount as per the volume on <br></br>our Adhoc rate AED 30/slide</p>
                        <p>10%</p>
                        <p>15%</p>
                        <p>20%</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Price/Slide<br></br>after Discount</p>
                        <p>AED 27.00</p>
                        <p>AED 25.50</p>
                        <p>AED 27.00</p>
                      </div>

                      <div class="time-est text-center">
                        <p class="font-bold mb-2">Estimated<br></br>Time</p>
                        <p>5 days</p>
                        <p>8 - 10 days</p>
                        <p>12 - 14 days</p>
                      </div>
                      
                    </div> 
                  </p>
                  
                  <h3>Advance / Projects</h3><hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                    <div class="flex justify-between flex-wrap gap-4">

                      <div class="service text-center">
                        <p class="font-bold mb-2">Number of<br></br>Slides</p>
                        <p>0-100</p>
                        <p>101-200</p>
                        <p>201 or more</p>
                      </div>

                      <div class="discount text-center">
                        <p class="font-bold mb-2">Discount as per the volume on <br></br>our Adhoc rate AED 30/slide</p>
                        <p>10%</p>
                        <p>15%</p>
                        <p>20%</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Price/Slide<br></br>after Discount</p>
                        <p>AED 54.00</p>
                        <p>AED 51.50</p>
                        <p>AED 48.00</p>
                      </div>

                      <div class="time-est text-center">
                        <p class="font-bold mb-2">Estimated<br></br>Time</p>
                        <p>8 days</p>
                        <p>12 - 14 days</p>
                        <p>17 - 19 days</p>
                      </div>
                    </div> 
                  </p>
                  <hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                    <div class="flex justify-between flex-wrap gap-4">

                      <div class="service text-center">
                        <p class="font-bold mb-2">Number</p>
                        <p>1</p>
                        <p>2</p>
                      </div>

                      <div class="discount text-center">
                        <p class="font-bold mb-2">Service line</p>
                        <p>Translation Service</p>
                        <p>Data Processing</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Offering</p>
                        <p>EZ Assured</p>
                        <p>Data Visualization</p>
                      </div>

                      <div class="time-est text-center">
                        <p class="font-bold mb-2">List Price</p>
                        <p>AED 0.75/word</p>
                        <p>AED 368.00/word</p>
                      </div>

                      <div class="price text-center">
                        <p class="font-bold mb-2">Corporate Discount 15%</p>
                        <p>AED 0.637/word</p>
                        <p>AED 312 /word</p>
                      </div>

                    </div> 
                  </p>

                  <h3 class="mt-5">Discounts on Invoicing</h3><hr></hr>
                  <p class="text-gray-400 mt-2 mb-10">
                  <p>AED 25K – AED 50K.  |  Additional 5% Discount on the total invoice value</p>
                  <p>AED 50K.  |  Additional 10% Discount on the total invoice value</p>
                  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Ez Works will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              <div class="hidden" id="prezlab" role="tabpanel">
              <div class="w-full intro-line mt-10">
                  <p class="text-gray-400 mb-8">
                  Please visit PrezLab website for further details <a href="https://prezlab.com/ar/">here</a>
</p>
<h2>How to request </h2>

<p className="text-gray-400 mb-8">
  <li>Please send an email request to: Muneera AlSadah <a href="mailto:muneera.alsadah@prezlab.com">muneera.alsadah@prezlab.com</a></li>
  <li>Please loop your partner for approval</li>
  </p>
 <h2>Authorized level to approve a request</h2>
 <p className="text-gray-400 mb-8">
  <li>Authorized level to approve a request and a deliverable is: directors and partners only. Other levels are not authorized.</li>
</p>

<h2>What are the rates? </h2>
<p className="text-gray-400 mb-8">
  <li>-	PrezLab Rates Card <a href="https://drive.google.com/file/d/1rytcg5a09K52zU938icvk_PuTnkUgiK_/view?usp=sharing">here</a></li>
  </p>
<h2>What to do in case any issue arises? </h2>
<p className="text-gray-400 mb-8">
  <li>First step is to solve it with your partner</li>
  <li>In case of any other issues, please send an email to them and Cc: <a href="mailto:albaraa@tamhub.com">albaraa@tamhub.com</a> & <a href="mailto:muna.sheikh@tamhub.com">muna.sheikh@tamhub.com</a></li>
</p>
<h2>How to make the payment? </h2>
<p className="text-gray-400 mb-8">
  <li>A partner or director must approve the request + share the engagement code</li>
  <li>TAM have shared the attached “Invoice Guidelines” with all vendors</li>
  <li>Prezlab  will submit an invoice directly to accounting team</li>
  <li>The employee do not have to process payment or follow it.</li>
  <li>Payments will be completed within 5 to 10 days.</li>
</p>

                  

                </div>
              </div>
              
              
            </div>
          </div>

        </div>
        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>
      </div>
      <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
    </div>

  );
}

export default Agreemnts;
