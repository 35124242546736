import React from "react";
import ScriptTag from 'react-script-tag';
function Operation() {
  return (
    <div class="page-wrapper">
      <div class="header-wrapper">
        <div class="w-full intro-line">
          <img src="/assets/images/favicon.png" class="w-14"/>
          <h1>Operations & Delivery
          </h1>
        </div>
      </div>

      <div class="mb-4 ">
        <ul
          class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center"
          data-tabs-toggle="#MainTabs" id="mainTabsSelect"
          role="tablist">
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#authorities" role="tab">Authorities for Signing & Stamping</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#how-to " role="tab">Processes (How-To)
            </button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#performance" role="tab">Project Performance Report</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#fulltime" role="tab">Hiring Full-time Employee</button>
          </li>
          <li class="tab-element" role="presentation">
            <button class="tab-button" data-tabs-target="#documentation " role="tab">Project Documentation
            </button>
          </li>
        </ul>
      </div>

      <div class="section-wrapper">
        <div id="MainTabs">

          <div class="hidden" id="authorities" role="tabpanel">
            <div class="w-full intro-line">
              <h2>Authorities for Signing & Stamping</h2>
              <p class="text-gray-400 mb-10">
                <li>CEO: Sign & stamp client’s contracts only.
                </li>
                <li>COO: Sign & stamp vendors contracts above 500K</li>
                <li>Managing Partner: Sign & stamp vendors contracts from 100K to 500K</li>
                <li>Partners & directors: Sign & stamp below 100K contracts</li>
                <li>P.s. all stamps are made through the personal assistants
                </li>
              </p>
            </div>
          </div>

          <div class="hidden" id="how-to" role="tabpanel">
            <div class="mb-4">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#InnerTabs1" id="innerTabsSelect"
                role="tablist">
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#contract"
                    role="tab">Sign a Contract</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#p-budget"
                    role="tab">Project Budget & Financials</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#payment-r"
                    role="tab">Submit a Payment Request
                  </button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#contract-expert"
                    role="tab">Sign with a Contract-Based Expert</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block pr-6 rounded-t-lg"
                    data-tabs-target="#daily"
                    role="tab">Report Daily Allocations</button>
                </li>
              </ul>
            </div>

            <div id="InnerTabs1">
              <div class="hidden" id="contract" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Sign a Contract with Client</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Submit a request to your partner requesting to sign. Please make all above
                      in one email thread.
                    </li>
                    <li>Upon Manal’s review and comments, Shatha will lead Abdullah’s signature.
                    </li>
                    <li>If it required expediting, please highlight in your email with reason why it
                      is important to expedite.</li>
                  </p>
                </div>
              </div>

              <div class="hidden" id="p-budget" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Project Budget & Financials</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Please make sure you have an approved budget sheet with your partner
                    </li>
                    <li>Submit budget on “Engagement Payments” board on Monday
                      <li>All project managers or directors of project must upload their projects on
                        “Engagement payment” board on Monday. The structure:
                        <ol>
                          <li>Create a new group for your project code. List of project codes here.
                          </li>
                          <li>
                            Add a row for each payment
                          </li>
                          <li>Upload the approved budget
                          </li>
                        </ol>
                      </li>
                      <li>Request an invoice
                        <ol>
                          <li>Go to the row of the needed payment, and change invoice status to “request
                            invoice”.</li>
                          <li>Finance team will email you the invoice within 24 hours.
                          </li>
                        </ol>
                      </li>
                      <li>Modifications on invoice
                        <ol>
                          <li>Each modification is a special case. Please submit a request with details on
                            TAM911
                          </li>
                        </ol>
                      </li>
                    </li>
                  </p>
                </div>
              </div>

              <div class="hidden" id="payment-r" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Submit a Payment Request
                  </h2>
                  <p class="text-gray-400 mb-10">
                    <li>Process of submitting a payment request
                      <li>Submit a request on PR attaching the invoice
                      </li>
                      <li>Make sure you follow the <a href="/assets/files/invoice-guidelines.docx">Invoice Guidelines</a>
                      </li>
                      <li>Each payment takes the following steps:
                        <ol>
                          <li>Submit by employee</li>
                          <li>Technical review by director or partner: to make sure that the payment is
                            due and vendor completed the work correctly.
                          </li>
                          <li>Financial review by finance team: to make sure there is a budget and
                            allocate expense to the right budget
                          </li>
                          <li>CFO: Final audit review on all payments
                          </li>
                          <li>Bank transfer
                          </li>
                        </ol>
                      </li>
                      <li>Upon approving director or partner, the payment will take 3 to 5 days to
                        transfer.
                      </li>
                    </li>
                    <li>Following up a request
                      <li>Submit a request on TAM911 requesting PR status. Include PR information
                      </li>
                    </li>
                    <li>Emergency requests
                      <li>Directors, partners, and above are eligible to request an emergency payment.
                      </li>
                      <li>Requests received by other grades will not be rejected.
                      </li>
                      <li>Emergency payments will take less than 24 hours to transfer payment
                      </li>
                      <li>Transfer is subject to completing all financial documentations
                      </li>
                    </li>
                  </p>

                </div>
              </div>
              <div class="hidden" id="contract-expert" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Sign with a Contract-Based Expert</h2>
                  <p class="text-gray-400 mb-10">
                    <li>Request to hire a project-based or part-time:
                      <li>Ensure you have an available budget
                      </li>
                      <li>Heads, directors, and above are authorized to hire a project-based team member.
                      </li>
                      <li>Sign contract-based template agreement.
                      </li>
                      <li>Send all highlighted documentation to HR and contract to process the payment.
                      </li>
                      <li>The agreement will be monthly, and renewable.
                      </li>
                    </li>
                    <li>Request to hire an international expert: (business visa)

                      <li>Process of hiring is similar to project-based.
                      </li>
                      <li>To issue a work visa:

                        <ol>
                          <li>Collect passport copy & submit a request on 911 with following details:

                            <li>Passport copy & resume</li>
                            <li>
                              Start date and end date of stay.</li>
                            <li>Role of the candidate and in which project
                            </li>
                            <li>Who is the direct manager
                            </li>
                          </li>
                        </ol>
                      </li>
                    </li>

                    <div class="flex flex-col sm:flex-row flex-wrap w-full gap-2">
                            <a target="_blank" href="https://docs.google.com/document/d/1R1eejHTDaYNcfR-cLRkPJb1XRZvJ1tuj/edit" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">A SME or a Contract-based</p>
                            </a>
                            <a target="_blank" href="https://docs.google.com/document/d/119YMJuEwhIFY5taqZ-WMn09t_EXAIuQ4/edit?rtpof=true&sd=true" class="mb-5 bg-white flex flex-row items-center px-5 py-8 rounded-2xl shadow-lg w-1/3">
                                <img class="w-5" src="/assets/images/download-file.svg"></img>
                                <p class="mx-5">NDA</p>
                            </a>
                        </div>
                  </p>

                </div>
              </div>
              <div class="hidden" id="daily" role="tabpanel">

                <div class="w-full intro-line mt-10">
                  <h2>Coming Soon</h2>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden" id="performance" role="tabpanel">

            <div class="w-full intro-line mt-2">

              <h2>Project Performance Report</h2>
              <p class="text-gray-400 mb-10">
                <li>Performance report includes information about: contract details, payments
                  status, expenses, profit & loss report, resources cost, vendors cost.
                </li>
                <li>Performance report is published monthly by the finance team.
                </li>
                <li>In case you needed a report at anytime of the month, please send a request
                  on TAM911 if you’d like the “Project Performance Report”</li>
              </p>
            </div>

          </div>

          <div class="hidden" id="fulltime" role="tabpanel">
            <div class="w-full intro-line  mt-2">

              <h2>Hiring Full-time Employee</h2>
              <p class="text-gray-400 mb-10">
                <li>Manpower plans require an approval from a partner & COO. Manpower is the
                  list of vacancies requires for a specific period.
                </li>
                <li>Submit a request to your partner via email</li>
                <li>If approved, partner will submit to COO
                </li>
                <li>If approved, talent acquisition team will be assigned to fill that vacancy
                  as per their plan.
                </li>
                <li>If you need a status update on the vacancy, interviews, starting dates,
                  please submit a ticket on 911.</li>
              </p>
            </div>
          </div>

          <div class="hidden" id="documentation" role="tabpanel">
            <div class="w-full intro-line mt-2">

              <h2>Project Documentation</h2>
              <p class="text-gray-400 mb-10">
                Please visit <a href="https://tam-llc.monday.com/boards/403724164" target="_blank">Monday.com</a>, board titled “Operation Kit”
                <br></br>
                <br></br>

                You will find the following information:
                <li>List of projects & a G-drive for all content</li>
                <li>Vendors list</li>
                <li>Experts database
                </li>
                <li>Check-list</li>
                <li>TAM portfolio</li>

              </p>
            </div>
          </div>

        </div>
        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>
      </div>
      <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
    </div>
  );
}

export default Operation;
