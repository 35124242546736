import React from "react";
import ScriptTag from 'react-script-tag';
function Knowledge() {
  return (
    <div class="page-wrapper">
    <div class="header-wrapper">
        <div class="w-full intro-line">
            <img src="/assets/images/favicon.png" class="w-14"/>
            <h1>Human Resources</h1>
        </div>
    </div>


    <div class="mb-4 ">
        <ul class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center" data-tabs-toggle="#MainTabs" id="mainTabsSelect" role="tablist">
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#business-trips"  role="tab" >Business Trips</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#hiring"  role="tab" >Hiring</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#insurance"  role="tab" >Health Insurance </button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#relations"  role="tab" >Government Relations</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#palm"  role="tab" >PalmHR</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#business"  role="tab" >Business Cards</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#promotions"  role="tab" >Promotions & Evaluations</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#training"  role="tab" >Training & Development</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#stamps"  role="tab" >Chamber of Commerce Stamps</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#loan"  role="tab" >Loan</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#complaints"  role="tab" >Complaint</button>
            </li>
            
            
            
        </ul>
    </div>


    <div class="section-wrapper">
        <div id="MainTabs">
            <div class="hidden" id="business-trips" role="tabpanel" >
            <div class="mb-4">
                    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" data-tabs-toggle="#InnerTabs1" id="innerTabsSelect" role="tablist">
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#flight" role="tab">Booking a Flight</button>
                        </li>
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#car" role="tab">Renting a Car</button>
                        </li>
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#transportation" role="tab">Transportation Services</button>
                        </li>
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#hotel" role="tab">Hotel Booking</button>
                        </li>
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#reimbursements" role="tab">Reimbursements</button>
                        </li>
                        
                        
                    </ul>
                    
                </div>

                <div id="InnerTabs1">
                    <div class="hidden" id="flight" role="tabpanel" >
                        
                    </div>
                    <div class="hidden" id="car" role="tabpanel" >

                    </div>
                    <div class="hidden" id="transportation" role="tabpanel" >
                    
                    </div>
                    <div class="hidden" id="hotel" role="tabpanel" >
                        
                    </div>
                    <div class="hidden" id="reimbursements" role="tabpanel" >
                        
                    </div>
                    
                </div>
            </div>
            <div class="hidden" id="hiring" role="tabpanel" >

            </div>
            <div class="hidden" id="insurance" role="tabpanel" >

            </div>
            <div class="hidden" id="relations" role="tabpanel" >

            </div>
            <div class="hidden" id="palm" role="tabpanel" >

            </div>
            <div class="hidden" id="business" role="tabpanel" >

            </div>
            <div class="hidden" id="promotions" role="tabpanel" >

            </div>
            <div class="hidden" id="training" role="tabpanel" >

            </div>
            <div class="hidden" id="stamps" role="tabpanel" >

            </div>
            <div class="hidden" id="loan" role="tabpanel" >

            </div>
            <div class="hidden" id="complaints" role="tabpanel" >

            </div>
            
            
            
            
        </div>
      
    </div>

    <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
</div>
  );
}

export default Knowledge;
