import React from "react";
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Overall,
  Human_Resources,
  Legal,
  Financials,
  Tech,
  Agreemnts,
  Knowledge,
  Operation,
  Guidelines,
  AuthPage,
  ProtectedRoute,
  LoginPage,

  
} from "./components";
import { CLEAR_ALL } from "./util/actionTypes";

const App = ({ loggedIn, logout }) =>(
  
  <Router >
    <Navigation />
    <Switch>
    <ProtectedRoute
          component={LoginPage}
          open={!loggedIn}
          path="/login"
          redirect="/home"
        />
    <ProtectedRoute
            component={Overall}
          open={loggedIn}
          path="/Overall"
          redirect="/login"
        />
        <ProtectedRoute
          component={Home}
          open={loggedIn}
          path="/home"
          redirect="/login"
        />
        <ProtectedRoute
          component={Human_Resources}
          open={loggedIn}
          path="/Human_Resources"
          redirect="/login"
        />
        <ProtectedRoute
          component={Legal}
          open={loggedIn}
          path="/Legal"
          redirect="/login"
        />
        <ProtectedRoute
          component={Financials}
          open={loggedIn}
          path="/Financials"
          redirect="/login"
        />
        <ProtectedRoute
          component={Tech}
          open={loggedIn}
          path="/Tech"
          redirect="/login"
        />
        <ProtectedRoute
          component={Agreemnts}
          open={loggedIn}
          path="/Agreemnts"
          redirect="/login"
        />
        <ProtectedRoute
          component={Knowledge}
          open={loggedIn}
          path="/Knowledge"
          redirect="/login"
        />
        <ProtectedRoute
          component={Operation}
          open={loggedIn}
          path="/Operation"
          redirect="/login"
        />

        <ProtectedRoute
          component={Guidelines}
          open={loggedIn}
          path="/Guidelines"
          redirect="/login"
        />
        
        <Route
          component={AuthPage}
          path="/auth"
        />
        
        <Route path="/">
          <Redirect to="/home" />
        </Route>
      
    </Switch>
    <Footer />
    
    </Router>

);

const mapStateToProps = state => ({
  loggedIn: !!state.token.accessToken
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: CLEAR_ALL })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

