import { stringify } from "query-string";

// Static oidc params for a single provider
const authority = "https://tam.onelogin.com/oidc/2";
const client_id = "c8216080-2a0a-013b-1e6f-06f3f69a802d68918";
const redirect_uri = "https://portal.tamhub.com/auth";
const response_type = "id_token token";
const scope = "openid profile";

export const beginAuth = ({ state, nonce }) => {
  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce
  });
  const authUrl = `${authority}/auth?${params}`;
  console.log(authUrl);

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};
