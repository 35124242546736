import React from "react";

// var s = document.getElementById("contact_form_container");
// var t = document.querySelector(".section-wrapper")
// t.appendChild(s);

function Contact_Form() {
  return (
    <div id="contact_form_container">
        <hr class="mt-8" />
        <div class="flex flex-row items-center">
          <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4"/>
          <h2 class="my-8">Didn’t find what you’re looking for?</h2>
        </div>
        <form id="contact_form" action="form.php" method="post">
            <div class="flex flex-wrap w-full gap-8">
              <input class="form-field" placeholder="Name" type="text" name="name"/>
              <input class="form-field" placeholder="Email" type="text" name="email"/>
              <select class="form-field" id="lists" name="lists">
                    <option value="" disabled selected>Request To</option>
                    <option  value="b.alanazi@tamhub.com">Human Resources</option>
                    <option  value="m.basalasel@tamhub.com">Government Relations</option>
                    <option  value="b.alahmari@tamhub.com">Recruitment</option>
                    <option  value="a.abdelmoneim@tamhub.com">Payment Requests</option>
                    <option  value="a.khairy@tamhub.com">Finance</option>
                    <option  value="albaraa@tamhub.com">Legal</option>
                </select>
            </div>
            
            <textarea class="form-field" placeholder="Message"  name="message" ></textarea>
              <input class="form-button text-xs" type="submit" name="submit" value="Submit"/>
              </form>
      </div>
  );
}

export default Contact_Form;
