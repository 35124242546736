import React from "react";
import ScriptTag from 'react-script-tag';
function Home() {
  return (
    <div class="page-wrapper">

        

      <div class="section-wrapper">
        <div class="header-wrapper">
            <div class="w-full intro-line">
                <img src="/assets/images/main-diamond.svg" class="w-14 main-diamond absolute"/>
                <p>Hello there!</p>
                <h1>How can we Help?</h1>
                <p class="text-gray-400 mb-10">TAM portal aims to provide tools and answers to all TAMers in the fastest and easiest way. We aim to eliminate the time to figure out where to get the information, asking the question, and waiting for the answer.</p>
            </div>
        </div>
        <hr class="mb-8"></hr>
        <div class="w-full intro-line">
                        <h2>What can I Find in TAM Portal?</h2>
                        <p class="text-gray-400 mb-8">
                        In the first version for the portal, you will find answers to all questions directed to support services, to help you know how to get what you need in the fastest possible way. 
                        <br></br>
                        All questions related to human resources, legal, finance, office, technology and software, vendors & 3rd party agreements, and design guidelines files. 
                        </p>

                        <h2>What If I Did Not Find the Answer?</h2>
                        <p class="text-gray-400 mb-8">
                        If you could not find your answer, please submit a question or book a meeting, we will respond to your question within 24 hours and add the answer in the portal for everyone else to benefit.</p>

                        <h2>Can I Still Go to HR or Finance Office without an Appointment?</h2>
                        <p class="text-gray-400 mb-8">
                        We are planning to stop that practice as it disturbs the workflow and productivity of support services team. We will be looking timeslots for meetings, and we will be attending to all questions as fast as we can.</p>
        </div>

        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>
      </div>

      <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
    </div>
  );
}

export default Home;
