import React from "react";
import ScriptTag from 'react-script-tag';
function Tech() {
  return (
    <div class="page-wrapper">
    <div class="header-wrapper">
        <div class="w-full intro-line">
            <img src="/assets/images/favicon.png" class="w-14"/>
            <h1>Technology & Subscriptions</h1>
        </div>
    </div>


    <div class="mb-4 ">
        <ul class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center" data-tabs-toggle="#MainTabs" id="mainTabsSelect" role="tablist">
            <li class="tab-element" role="presentation">
                <button class="tab-button text-xs" data-tabs-target="#hardware"  role="tab" >Technical Support</button>
            </li>
            {/* <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#tech-support"  role="tab" >Technical Support</button>
            </li> */}
        </ul>
    </div>


    <div class="section-wrapper">
        <div id="MainTabs">
            <div class="hidden" id="hardware" role="tabpanel" >
                <div class="w-full intro-line">
                    <h2>Request New Hardware</h2>
                    <p class="text-gray-400 mb-10">
                        <li>Submit a request on TAM911 with detailed request.</li>
                        <li>The team will respond within 24 hours, in case purchase is approved, the team will provide the hardware within 3 to 5 business days.</li>
                    </p>
                </div> 

                <div class="w-full intro-line">
                    <h2>Maintenance</h2>
                    <p class="text-gray-400 mb-10">
                        <li>Submit a request on TAM911 within detailed request.</li>
                        <li>The team will respond within few hours with actions depending on the situation </li>
                    </p>
                </div> 

                <div class="w-full intro-line">
                    <h2>Technical Support</h2>
                    <p class="text-gray-400 mb-10">
                    If you are facing any issue with any of your hardware or software, please submit a ticket with detailed issue in Portal. 
                    <br></br><br></br>
                    Expect the team to come back to you within 24 hours, and depending on the request, they will inform you how long will it take to fix it.
                    </p>
                </div> 
            </div>

            <div class="hidden" id="tech-support" role="tabpanel" >

            </div>            
        </div>

        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>
    </div>

    <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
</div>
  );
}

export default Tech;
