import React from "react";
import ScriptTag from 'react-script-tag';
function Overall() {
  return (
    <div class="page-wrapper">
    <div class="header-wrapper">
        <div class="w-full intro-line">
            <img src="/assets/images/favicon.png" class="w-14"/>
            <h1>TAM Overall</h1>
        </div>
    </div>


    <div class="mb-4 ">
        <ul class="gap-4 flex flex-wrap -mb-px text-sm font-medium text-center" data-tabs-toggle="#MainTabs" id="mainTabsSelect" role="tablist">
            <li class="tab-element" role="presentation">
                <button class="tab-button"  data-tabs-target="#strategy"  role="tab" >TAM Strategy</button>
            </li>
            <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#values"  role="tab" >Values</button>
            </li>
            {/* <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#structure"  role="tab" >Organization Structure</button>
            </li> */}
            {/* <li class="tab-element" role="presentation">
                <button class="tab-button" data-tabs-target="#directory"  role="tab" >Employee Directory</button>
            </li> */}
            
        </ul>
    </div>


    <div class="section-wrapper">
        <div id="MainTabs">
            <div class="hidden" id="strategy" role="tabpanel" >
                {/* <div class="w-full intro-line">
                    <h2>Vision</h2>
                    <p class="text-gray-400 mb-10">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release.</p>
                </div> 
    
                <div class="w-full intro-line">
                    <h2>Mission</h2>
                    <p class="text-gray-400 mb-10">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release.</p>
                </div> 
    
                <div class="w-full intro-line">
                    <h2>Introducing TAM to Others</h2>
                    <p class="text-gray-400 mb-10">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release.</p>
                </div> 
    
                <div class="w-full intro-line">
                    <h2>What We Do?</h2>
                    <p class="text-gray-400">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release.</p>
                </div>  */}

                <div class="w-full intro-line">
                    <h2>Coming Soon</h2>
                </div> 

            </div>

            <div class="hidden" id="values" role="tabpanel" >

                <div class="w-full intro-line">
                    <h2>Respect the Truth </h2>
                    <p class="text-gray-400 mb-10">It doesn’t matter who says it, the truth is appreciated and taken with due diligence to achieve what’s in the best interest for TAM.</p>
                </div> 
    
                <div class="w-full intro-line">
                    <h2>Win as a Team</h2>
                    <p class="text-gray-400 mb-10">We work together towards one common goal. We play with a dynamic collaborative approach. Out job titles are there to guide but not restrict our efforts and contributions.</p>
                </div> 
    
                <div class="w-full intro-line">
                    <h2>Grow with Wellbeing</h2>
                    <p class="text-gray-400 mb-10">We are committed to growth as individuals, teams and as a whole organization. As a learning organization tapping into novel areas, we remain mindful of the total wellbeing of our people in that growth journey. Growth with wellbeing means scaling public impact sustainably.</p>
                </div> 
    
                <div class="w-full intro-line">
                    <h2>Innovate and Adapt</h2>
                    <p class="text-gray-400 mb-10">The world is continuously changing, which makes it essential to keep up by always innovating ourselves and ways of work and adapting to that change.</p>
                </div> 

                <div class="w-full intro-line">
                    <h2>Own your Impact</h2>
                    <p class="text-gray-400">We are connected to the purpose of what we do, and the impact it creates in the society. Our sense of ownership, dedication and commitment to excellence is always present.</p>
                </div> 

            </div>

            <div class="hidden" id="structure" role="tabpanel" >

            </div>
            <div class="hidden" id="directory" role="tabpanel" >
                <div class="mb-4 flex justify-between items-center flex-wrap gap-y-4">
                    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center"  data-tabs-toggle="#InnerTabs1" id="innerTabsSelect" role="tablist">
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#portraits" role="tab">Portraits</button>
                        </li>
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#cvs" role="tab">CVs</button>
                        </li>
                        <li class="mr-2" role="presentation">
                            <button class="inline-block pr-6 rounded-t-lg" data-tabs-target="#contacts" role="tab">Contacts</button>
                        </li>
                        
                    </ul>
                    <div class="relative">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </div>
                        <input type="search" id="default-search" class="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by name" required/>
                    </div>
                </div>

                <div id="InnerTabs1">
                    <div class="hidden" id="portraits" role="tabpanel" >
                        
                            <h2 class="mb-4">TAM Members</h2>
                          
                          <div class="search-result flex flex-wrap -m-4">
                            <div class="p-4 lg:w-1/4 md:w-1/2">
                              <div class="h-full flex flex-col items-center text-center">
                                <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                <div class="w-full">
                                  <h3 class="search-name">Abdullah Alyousef</h3>
                                  <h3 class="text-gray-500 mb-3">CEO</h3>
                                  
                                </div>
                              </div>
                            </div>

                            <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>
                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>
                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Mazen</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex flex-col items-center text-center">
                                  <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="https://dummyimage.com/200x200"/>
                                  <div class="w-full">
                                    <h3 class="search-name">Abdullah Alyousef</h3>
                                    <h3 class="text-gray-500 mb-3">CEO</h3>
                                    
                                  </div>
                                </div>
                              </div>
                            
                          </div>
                    </div>
                    <div class="hidden" id="cvs" role="tabpanel" >

                        <h2 class="mb-4">TAM Member’s CVs</h2>

                        <div class="search-result flex flex-wrap -m-4">
                         

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <a class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>
                                    </div>
                                </a>
                              </div>
                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <a class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>
                                    </div>
                                </a>
                              </div>
                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <a class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>
                                    </div>
                                </a>
                              </div>
                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <a class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>
                                    </div>
                                </a>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <a class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>
                                    </div>
                                </a>
                              </div>
                              
                            
                          </div>
                    </div>
                    <div class="hidden" id="contacts" role="tabpanel" >
                        <h2 class="mb-4">TAM Members Contacts</h2>

                        <div class="search-result flex flex-wrap -m-4">
                         

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>

                                      <a class="phone block text-gray-500 mt-4">+966550987987</a>
                                      <a class="email block text-gray-500">m.malibari@tamhub.com</a>
                                    </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>

                                      <a class="phone block text-gray-500 mt-4">+966550987987</a>
                                      <a class="email block text-gray-500">m.malibari@tamhub.com</a>
                                    </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>

                                      <a class="phone block text-gray-500 mt-4">+966550987987</a>
                                      <a class="email block text-gray-500">m.malibari@tamhub.com</a>
                                    </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>

                                      <a class="phone block text-gray-500 mt-4">+966550987987</a>
                                      <a class="email block text-gray-500">m.malibari@tamhub.com</a>
                                    </div>
                                </div>
                              </div>

                              <div class="p-4 lg:w-1/4 md:w-1/2">
                                <div class="h-full flex items-center ">
                                    
                                      
                                    <div class="flex-grow">
                                      <h3 class="dark-blue search-name">Mazen Malibari</h3>
                                      <p class="text-gray-500">Intelligent Officer</p>

                                      <a class="phone block text-gray-500 mt-4">+966550987987</a>
                                      <a class="email block text-gray-500">m.malibari@tamhub.com</a>
                                    </div>
                                </div>
                              </div>
                              
                              
                            
                          </div>
                    </div>
                    
                </div>
            </div>
            
        </div>

        <div id="contact_form_container">
    <hr class="mt-8" />
    <div class="flex flex-row items-center">
      <img src="/assets/images/favicon.png" class="w-8 h-8 mr-4" />
      <h2 class="my-8">Didn’t Find What You’re Looking For?</h2>
    </div>
    <a class="form-button px-12 py-3 text-xs" href="https://form.jotform.com/202502608884052" target="_blank"> Submit
      TAM911 Request</a>

  </div>
      
    </div>

    <ScriptTag type="text/javascript" src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js" />
    <ScriptTag type="text/javascript" defer src="../js/script.js" />
</div>
  );
}

export default Overall;


