import React from "react";

function Footer() {
  return (
    <div className="footer">
      <span class="text-gray-700"><b>Call</b> 053 082 2008</span>
      <span class="text-gray-700">Copyrights, <b>TAM Development</b> | 2022 </span>
    </div>
  );
}

export default Footer;
